import React from "react";
import { useNavigate } from "react-router-dom";
import { getImage } from "../utils";
import { useWindowWidth } from "../context/WindowWidthContext";

const SquareBox = ({ imageUrl, text, description, id }) => {
  const { isMobile } = useWindowWidth();
  const navigate = useNavigate();
  const boxStyle = {
    width: !isMobile ? "150px" : "85px",
    height: !isMobile ? "150px" : "85px",
    overflow: "hidden",
    border: "1px solid #fff",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: "10px",
    boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
  };

  const imageStyle = {
    width: "140%",
    height: "140%",
    objectFit: "cover",
    top: 0,
    left: 0,
  };

  const rootBox = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
  };

  return (
    <div
      style={rootBox}
      onClick={() => {
        navigate(`/category/${id}`);
      }}
    >
      <div style={boxStyle}>
        <img src={getImage(imageUrl)} alt="Square" style={imageStyle} />
      </div>
      <div
        style={{
          color: "#000",
          fontWeight: 500,
          fontSize: !isMobile ? "16px" : "14px",
          textAlign: "center",
        }}
      >
        {text}
      </div>
    </div>
  );
};

export default SquareBox;
