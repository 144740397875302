import httpService from "../utils/request";
import { create } from "./index";

const getListLanguage = () => {
  return httpService.get("/public/languages");
};

const createLanguage = async (formData) => {
  const res = await create("/private/languages", formData);
  return res;
};

const editLanguage = (languageId, formData) => {
  return httpService.put(`/private/languages/${languageId}`, formData);
};

export const languageService = {
  getListLanguage,
  createLanguage,
  editLanguage,
};
