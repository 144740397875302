import React, { useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import httpService from "../../../../utils/request";
import { useToast } from "../../../../context/ToastContext";

const languageSchema = z.object({
  payment: z.string().min(1, "Trạng thái là bắt buộc"),
});

const EditLanguageModal = ({ show, handleClose, language, handleUpdate }) => {
  const showToast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: language,
    resolver: zodResolver(languageSchema),
  });

  useEffect(() => {
    if (language) {
      reset(language);
    }
  }, [language, reset]);

  const status = watch("status");

  const onSubmit = async (data) => {
    try {
      const response = await httpService.put(`/private/users/${language.id}`, {
        payment: +data.payment,
      });
      handleUpdate({ id: language.id, ...data, payment: +data.payment });
      showToast("Chỉnh sửa học viên thành công", {
        type: "success",
      });
      reset();
    } catch (error) {
      showToast("Có lỗi xảy ra! Chỉnh sửa không thành công", {
        type: "error",
      });
    }
  };

  const handleCloseModal = () => {
    reset();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>Chỉnh sửa học viên</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="languageName">
            <Form.Label>
              Họ Tên <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              disabled
              type="text"
              {...register("name")}
              isInvalid={!!errors.name}
            />
            {errors.name && (
              <Form.Control.Feedback type="invalid">
                {errors.name.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="username">
            <Form.Label>
              Tài Khoản <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              disabled
              type="text"
              {...register("username")}
              isInvalid={!!errors.username}
            />
            {errors.username && (
              <Form.Control.Feedback type="invalid">
                {errors.username.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="payment">
            <Form.Label>
              Trạng Thái Thanh Toán <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              as="select"
              {...register("payment", { required: "Trạng thái là bắt buộc" })}
              isInvalid={!!errors.payment}
            >
              <option value="">Select</option>
              <option value="0">Chưa Thanh Toán</option>
              <option value="1">Đã Thanh Toán</option>
            </Form.Control>
            {errors.password && (
              <Form.Control.Feedback type="invalid">
                {errors.payment.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Đóng
            </Button>
            <Button variant="primary" type="submit">
              Cập nhật
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditLanguageModal;
