import React, { useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const rootCategorySchema = z.object({
  name: z.string().min(1, "Tên danh mục là bắt buộc"),
  description: z.string().min(1, "Mô tả là bắt buộc"),
  rootLanguageId: z.number().min(1, "Ngôn ngữ là bắt buộc"),
  color: z.string().optional(),
  isGreatCategory: z.boolean().optional(),
  isUpdateCategory: z.boolean().optional(),
  isListeningCategory: z.boolean().optional(),
  isJLPT: z.boolean().optional(),
  isKaiwa: z.boolean().optional(),
});

const EditRootCategoryModal = ({
  show,
  handleClose,
  rootCategory,
  handleUpdate,
  languages,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: rootCategory,
    resolver: zodResolver(rootCategorySchema),
  });

  useEffect(() => {
    if (rootCategory) {
      reset(rootCategory);
    }
  }, [rootCategory, reset]);

  const onSubmit = (data) => {
    handleUpdate({ ...rootCategory, ...data });
    handleClose();
    reset();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Chỉnh sửa danh mục gốc</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="rootCategoryName">
            <Form.Label>Tên danh mục</Form.Label>
            <Form.Control
              type="text"
              {...register("name")}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="rootCategoryDescription">
            <Form.Label>Mô tả</Form.Label>
            <Form.Control
              type="text"
              {...register("description")}
              isInvalid={!!errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {errors.description?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="color">
            <Form.Label>Màu</Form.Label>
            <Form.Control
              type="text"
              {...register("color")}
              isInvalid={!!errors.color}
            />
            {errors.color && (
              <Form.Control.Feedback type="invalid">
                {errors.color.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="rootLanguageId">
            <Form.Label>Ngôn ngữ</Form.Label>
            <Form.Control
              as="select"
              {...register("rootLanguageId")}
              isInvalid={!!errors.rootLanguageId}
            >
              <option value="">Chọn ngôn ngữ</option>
              {(languages || []).map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
            {errors.rootLanguageId && (
              <Form.Control.Feedback type="invalid">
                {errors.rootLanguageId.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Label>Tùy chọn menu</Form.Label>

          <Form.Check
            defaultChecked={false}
            type="checkbox"
            {...register("isJLPT")}
            label="Khóa học JLPT"
            isInvalid={!!errors.isJLPT}
          />
          {errors.isGreatCategory && (
            <Form.Control.Feedback type="invalid">
              {errors.isJLPT.message}
            </Form.Control.Feedback>
          )}

          <Form.Check
            defaultChecked={false}
            type="checkbox"
            {...register("isKaiwa")}
            label="Khóa học Kaiwa"
            isInvalid={!!errors.isKaiwa}
          />
          {errors.isGreatCategory && (
            <Form.Control.Feedback type="invalid">
              {errors.isKaiwa.message}
            </Form.Control.Feedback>
          )}

          <Form.Group controlId="isGreatCategory">
            <Form.Check
              defaultChecked={false}
              type="checkbox"
              {...register("isGreatCategory")}
              label="Khóa học hay nhất"
              isInvalid={!!errors.isGreatCategory}
            />
            {errors.isGreatCategory && (
              <Form.Control.Feedback type="invalid">
                {errors.isGreatCategory.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Check
            defaultChecked={false}
            type="checkbox"
            {...register("isUpdateCategory")}
            label="Khóa học mới nhất"
            isInvalid={!!errors.isUpdateCategory}
          />
          {errors.isGreatCategory && (
            <Form.Control.Feedback type="invalid">
              {errors.isUpdateCategory.message}
            </Form.Control.Feedback>
          )}

          <Form.Check
            defaultChecked={false}
            type="checkbox"
            {...register("isListeningCategory")}
            label="Khóa học luyện thi"
            isInvalid={!!errors.isListeningCategory}
          />
          {errors.isGreatCategory && (
            <Form.Control.Feedback type="invalid">
              {errors.isListeningCategory.message}
            </Form.Control.Feedback>
          )}

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Đóng
            </Button>
            <Button variant="primary" type="submit">
              Cập nhật
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditRootCategoryModal;
