const TitlePrimary = ({ color, name, description }) => {
  return (
    <div>
      <div className="d-flex align-items-center pt-4">
        <div
          style={{
            width: "5px",
            backgroundColor: color,
            height: "24px",
            marginRight: "10px",
          }}
        ></div>
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>{name}</div>
      </div>
      <p style={{ fontSize: "16px", marginLeft: "15px", fontWeight: 500 }}>
        {description}
      </p>
    </div>
  );
};

export default TitlePrimary;
