import DataTable1 from "../../components/admin/DataTable";
import {
  EditButton,
  DeleteButton,
  ChangePassword,
} from "../../components/admin/button";
import { useEffect, useState } from "react";
import { fetchList, updatePut } from "../../services";
import CreateLanguageModal from "../../components/admin/modals/user/CreateLanguage";
import EditLanguageModal from "../../components/admin/modals/user/EditLanguage";
import EditLanguagePasswordModal from "../../components/admin/modals/user/EditPassword";
import DeleteConfirmModal from "../../components/admin/modals/user/DeleteLanguage";
import { useToast } from "../../context/ToastContext";
import { Accordion, Dropdown, ButtonGroup, Button } from "react-bootstrap";

const PAYMENT = [
  {
    id: 0,
    name: "Chưa Thanh Toán",
  },
  {
    id: 1,
    name: "Đã Thanh Toán",
  },
];

const ManageUser = () => {
  const showToast = useToast();
  const columns = [
    {
      accessorKey: "id",
      header: "ID",
      isFilter: false,
    },
    {
      accessorKey: "name",
      header: "Họ Tên",
      isFilter: true,
      filterFn: "includesString",
    },
    {
      accessorKey: "phone",
      header: "Số Điện Thoại",
      filterFn: "includesString",
      isFilter: true,
    },
    {
      accessorKey: "username",
      header: "Tên Đăng Nhập",
      isFilter: true,
    },
    {
      accessorKey: "payment",
      header: "Trạng Thái",
      enableSorting: true,
      filterFn: "equals",
      isFilter: true,
      type: "select",
      options: PAYMENT,
      cell: ({ row }) => {
        const isSelected = PAYMENT.find(
          (x) => x.id === row.original.payment
        )?.name;
        return (
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle variant="success" id={`dropdown-${row.id}`}>
              {isSelected || "Select"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {PAYMENT.map((option) => (
                <Dropdown.Item
                  key={option.id}
                  disabled={isSelected} // Disable if already selected
                >
                  {option.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
    {
      header: "Hành động",
      isFilter: false,
      cell: ({ row }) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <ChangePassword
            onClick={() => handleShowEditPasswordModal(row.original)}
          />
          <EditButton onClick={() => handleShowEditModal(row.original)} />
          <DeleteButton onClick={() => handleShowDeleteModal(row.original)} />
        </div>
      ),
    },
  ];

  const [listLanguages, setListLanguages] = useState([]);
  //   const [isLoading, setIsLoading] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditPasswordModal, setShowEditPasswordModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleShowCreateModal = () => setShowCreateModal(true);

  const handleShowEditModal = (item) => {
    setShowEditModal(true);
    setSelectedItem(item);
  };

  const handleShowEditPasswordModal = (item) => {
    setShowEditPasswordModal(true);
    setSelectedItem(item);
  };

  const handleShowDeleteModal = (item) => {
    setShowDeleteModal(true);
    setSelectedItem(item);
  };

  const handleCreate = (newLanguage) => {
    setListLanguages((prevLanguages) => [...prevLanguages, newLanguage]);
    setShowCreateModal(false);
  };

  const handleUpdate = (updatedLanguage) => {
    setListLanguages((prevLanguages) =>
      prevLanguages.map((language) =>
        language.id === updatedLanguage.id ? updatedLanguage : language
      )
    );
    setShowEditModal(false);
  };

  const handleUpdatePassword = (updatedLanguage) => {
    setShowEditPasswordModal(false);
  };

  const handleDelete = async () => {
    const response = await updatePut(`/private/users/${selectedItem.id}`, {
      status: false,
    });
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setListLanguages((prevDatas) =>
        prevDatas.filter((pre) => pre.id !== selectedItem.id)
      );
      showToast("Xóa thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }

    setShowDeleteModal(false);
  };

  useEffect(() => {
    const fetchListLanguages = async () => {
      const data = await fetchList("/private/users");
      setListLanguages(data);
    };
    fetchListLanguages();
  }, []);

  return (
    <div style={{ padding: "10px 0" }}>
      <h4>Quản lý học viên</h4>
      <Button
        variant="primary"
        className="mb-2"
        onClick={handleShowCreateModal}
      >
        Thêm mới
      </Button>
      <DataTable1 data={listLanguages} columns={columns} />
      <CreateLanguageModal
        show={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        handleCreate={handleCreate}
      />
      <EditLanguageModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        language={selectedItem}
        handleUpdate={handleUpdate}
      />

      <EditLanguagePasswordModal
        show={showEditPasswordModal}
        handleClose={() => setShowEditPasswordModal(false)}
        language={selectedItem}
        handleUpdate={handleUpdatePassword}
      />

      <DeleteConfirmModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default ManageUser;
