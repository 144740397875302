import React from "react";
import Sidebar from "../../components/admin/Sidebar";
import { Container } from "react-bootstrap";
import { isAdmin } from "../../utils";

const AdminLayout = ({ children }) => {
  if (!isAdmin()) {
    return (
      <div style={{ display: "flex" }}>
        <Container
          style={{
            backgroundColor: "#fff",
            padding: "0px 20px",
            borderRadius: "10px",
            marginTop: " 20px",
          }}
        >
          <div
            style={{
              height: "100px",
              textAlign: "center",
              justifyContent: "center",
              fontWeight: "bold",
              alignItems: "center",
              display: "flex",
            }}
          >
            Không có quyền truy cập
          </div>
        </Container>
      </div>
    );
  }
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />
      <Container
        style={{
          backgroundColor: "#fff",
          padding: "0px 20px",
          borderRadius: "10px",
          marginTop: " 30px",
        }}
      >
        {children}
      </Container>
    </div>
  );
};

export default AdminLayout;
