import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "../../../../context/ToastContext";
import httpService from "../../../../utils/request";

const languageSchema = z.object({
  name: z.string().min(1, "Họ tên là bắt buộc"),
  payment: z.string().min(1, "Trạng thái là bắt buộc"),
  username: z.string().min(1, "Tài khoản là bắt buộc"),
  password: z.string().min(5, "Mật khẩu là bắt buộc"),
});

const CreateLanguageModal = ({ show, handleClose, handleCreate }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      description: "",
      status: true,
    },
    resolver: zodResolver(languageSchema),
  });
  const showToast = useToast();

  const onSubmit = async (data) => {
    try {
      try {
        const response = await httpService.post("/private/users", {
          ...data,
          payment: +data.payment,
        });
        handleCreate({ id: response.id, ...data, payment: +data.payment });
        showToast("Thêm mói học viên thành công", { type: "success" });
        reset();
      } catch (error) {
        showToast(error.response.data, {
          position: "top-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      showToast("Có lỗi xảy ra", { type: "error" });
    }
  };

  const handleCloseModal = () => {
    reset();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>Thêm mới học viên</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="languageName">
            <Form.Label>
              Họ Tên <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              {...register("name")}
              isInvalid={!!errors.name}
            />
            {errors.name && (
              <Form.Control.Feedback type="invalid">
                {errors.name.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="username">
            <Form.Label>
              Tài Khoản <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              {...register("username")}
              isInvalid={!!errors.username}
            />
            {errors.username && (
              <Form.Control.Feedback type="invalid">
                {errors.username.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>
              Tạo mật khẩu <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="password"
              {...register("password")}
              isInvalid={!!errors.password}
            />
            {errors.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="payment">
            <Form.Label>
              Trạng Thái Thanh Toán <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              as="select"
              {...register("payment", { required: "Trạng thái là bắt buộc" })}
              isInvalid={!!errors.payment}
            >
              <option value="">Select</option>
              <option value="0">Chưa Thanh Toán</option>
              <option value="1">Đã Thanh Toán</option>
            </Form.Control>
            {errors.password && (
              <Form.Control.Feedback type="invalid">
                {errors.payment.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Đóng
            </Button>
            <Button variant="primary" type="submit">
              Thêm mới
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateLanguageModal;
