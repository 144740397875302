import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { SiZalo } from "react-icons/si";
import CourseList from "../../components/CourseItem";
import { fetchList } from "../../services";
import { useParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { getImage } from "../../utils";
import { useWindowWidth } from "../../context/WindowWidthContext";
import { useGlobalState } from "../../context/GlobalContext";

const styles = {
  contentMasterCategory: (isMobile) => ({
    backgroundColor: "#fff",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    padding: !isMobile ? "10px 30px" : "10px",
    margin: !isMobile ? "10px 20px 0 20px" : "10px 0 0 0",
    flex: 1,
  }),
  contentMasterCategoryLoading: (isMobile) => ({
    backgroundColor: "#fff",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    padding: !isMobile ? "10px 30px" : "10px",
    margin: !isMobile ? "10px 20px 0 20px" : "10px 0 0 0",
    flex: 1,
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  contentContact: (isMobile) => ({
    margin: !isMobile ? "5px 20px 0 20px" : "5px 0px 0 0px",
    backgroundColor: "#fff",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    padding: "10px 30px",
    display: "flex",
    alignItems: "center",
  }),
};

const GreatCategory = () => {
  const { isMobile } = useWindowWidth();
  const { state } = useGlobalState();
  const support = state.supportData;
  const { id } = useParams();
  const [masterCategory, setMasterCategory] = useState({
    categories: [],
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const rootDatas = await fetchList(
        `/public/master-categories/search?id=${id}`
      );
      if (rootDatas && rootDatas.length > 0) {
        setMasterCategory(rootDatas[0]);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <Container
      className={isMobile ? "mobile-container main-content" : "main-content"}
      style={{
        paddingBottom: "10px",
        flexGrow: 1,
        minHeight: "calc(100vh - 60px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {loading ? (
        <div style={styles.contentMasterCategoryLoading(isMobile)}>
          <TailSpin color="green" radius={"8px"} />
        </div>
      ) : (
        <div style={styles.contentMasterCategory(isMobile)}>
          <div
            style={{
              backgroundColor: "#98d659",
              padding: !isMobile ? "20px" : "10px",
              borderRadius: "10px",
            }}
          >
            <div className="d-flex" style={{ flexDirection: "row" }}>
              <div
                className="wrap-title-course"
                style={{
                  width: "75px",
                  boxShadow: !isMobile
                    ? "5px 5px 15px rgba(0, 0, 0, 0.3)"
                    : "5px 5px 15px rgba(0, 0, 0, -0.7)",
                  borderRadius: "5px",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    boxShadow:
                      " 2px 2px 5px rgba(0, 0, 0, 0.3), -2px -2px 5px rgba(0, 0, 0, 0.2)",
                    borderRadius: "7px",
                  }}
                  src={getImage(masterCategory.image)}
                  alt=""
                />
              </div>
              <div
                className="d-flex"
                style={{ flexDirection: "column", paddingLeft: "10px" }}
              >
                <div className="d-flex">
                  <div
                    style={{
                      width: "10px",
                      backgroundColor: "green",
                      border: "1px solid #fff",
                      borderRadius: "5px",
                      height: "36px",
                      marginRight: "10px",
                      marginLeft: "10px",
                    }}
                  ></div>
                  <div
                    style={{
                      fontSize: !isMobile ? "24px" : "18px",
                      fontWeight: "bold",
                    }}
                  >
                    KHÓA HỌC: {masterCategory.name}
                  </div>
                </div>
                <div
                  style={{
                    fontSize: !isMobile ? "17px" : "15px",
                    marginLeft: "30px",
                    marginTop: !isMobile ? "0px" : "-7px",
                    fontWeight: 500,
                    color: "#000",
                  }}
                >
                  {masterCategory.description}
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: !isMobile ? "20px 0" : "10px 0" }}>
            <CourseList categories={masterCategory.categories} />
          </div>
        </div>
      )}

      <div style={styles.contentContact(isMobile)}>
        <SiZalo
          style={{ marginRight: "10px", color: "blue", fontSize: "30px" }}
        />
        <div style={{ fontWeight: "bold" }}>
          {support.name && support.phone
            ? `${support.name}: ${support.phone}`
            : "Ms. Yến: 0978365115"}
        </div>
      </div>
    </Container>
  );
};

export default GreatCategory;
