import React, { useState } from "react";
import { Nav, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./styles/Sidebar.css";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => setCollapsed(!collapsed);

  return (
    <div
      style={{
        width: collapsed ? "80px" : "250px",
        height: "100vh",
        backgroundColor: "#fff",
        transition: "width 0.3s",
      }}
    >
      <Button onClick={toggleSidebar} style={{ margin: "10px" }}>
        {collapsed ? ">" : "<"}
      </Button>
      <Nav
        className="flex-column p-3"
        style={{ display: collapsed ? "none" : "block" }}
      >
        <Nav.Link
          as={Link}
          to="/quanly"
          className={
            location.pathname === "/quanly" ? "active-link" : "nav-link"
          }
        >
          Quản lý khóa học
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/quanly/users"
          className={
            location.pathname === "/quanly/users" ? "active-link" : "nav-link"
          }
        >
          Quản lý users
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/quanly/payments"
          className={
            location.pathname === "/quanly/payments"
              ? "active-link"
              : "nav-link"
          }
        >
          Quản lý thanh toán
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/quanly/setting"
          className={
            location.pathname === "/quanly/setting" ? "active-link" : "nav-link"
          }
        >
          Cấu hình
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/quanly/manage-teacher"
          className={
            location.pathname === "/quanly/manage-teacher"
              ? "active-link"
              : "nav-link"
          }
        >
          Quản lý giáo viên
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/quanly/manage-language"
          className={
            location.pathname === "/quanly/manage-language"
              ? "active-link"
              : "nav-link"
          }
        >
          Quản lý loại ngôn ngữ
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/"
          className={location.pathname === "/" ? "active-link" : "nav-link"}
        >
          Xem trang web
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/login"
          onClick={() => {
            localStorage.removeItem("token");
            navigate("/");
          }}
          className={
            location.pathname === "/login" ? "active-link" : "nav-link"
          }
        >
          Đăng xuất
        </Nav.Link>
      </Nav>
    </div>
  );
};

export default Sidebar;
