import "./styles/descriptionMenu.css";
import Carousel from "react-bootstrap/Carousel";
import { DOMAIN } from "../constants";

const SessionMenuDescription = ({ posters }) => {
  return (
    <div style={{ border: "2px solid #8bd08c", borderRadius: "20px" }}>
      <Carousel>
        {posters.map((x) => (
          <Carousel.Item key={x.id}>
            <img
              style={{
                height: "auto",
                objectFit: "cover",
                borderRadius: "20px",
              }}
              className="d-block w-100"
              src={`${DOMAIN}${x.image}`}
              alt=""
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default SessionMenuDescription;
