import { useMemo } from "react";
import DataTable1 from "../../components/admin/DataTable";
import { EditButton, DeleteButton } from "../../components/admin/button";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import CreateLanguageModal from "../../components/admin/modals/teacher/CreateLanguage";
import EditLanguageModal from "../../components/admin/modals/teacher/EditLanguage";
import DeleteConfirmModal from "../../components/admin/modals/teacher/DeleteLanguage";
import { fetchList, updatePut } from "../../services";
import { useToast } from "../../context/ToastContext";
import { getImage } from "../../utils";

const ManageLanguage = () => {
  const showToast = useToast();

  const [listLanguages, setListLanguages] = useState([]);
  //   const [isLoading, setIsLoading] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        isFilter: false,
      },
      {
        accessorKey: "name",
        header: "Tên giáo viên",
      },
      {
        accessorKey: "image",
        header: "Ảnh",
        cell: ({ row }) => (
          <img
            src={getImage(row.original.image)}
            alt={row.original.name}
            style={{ width: "150px", height: "auto", objectFit: "cover" }}
          />
        ),
      },
      {
        header: "Hành động",
        isFilter: false,
        cell: ({ row }) => (
          <div style={{ display: "flex", gap: "10px" }}>
            <EditButton onClick={() => handleShowEditModal(row.original)} />
            <DeleteButton onClick={() => handleShowDeleteModal(row.original)} />
          </div>
        ),
      },
    ],
    [listLanguages]
  );

  const handleShowCreateModal = () => setShowCreateModal(true);

  const handleShowEditModal = (item) => {
    setShowEditModal(true);
    setSelectedItem(item);
  };
  const handleShowDeleteModal = (item) => {
    setShowDeleteModal(true);
    setSelectedItem(item);
  };

  const handleCreate = (newLanguage) => {
    setListLanguages((prevLanguages) => [...prevLanguages, newLanguage]);
    setShowCreateModal(false);
  };

  const handleUpdate = (updatedLanguage) => {
    setListLanguages((prevLanguages) =>
      prevLanguages.map((language) =>
        language.id === updatedLanguage.id ? updatedLanguage : language
      )
    );
    setShowEditModal(false);
  };

  const handleDelete = async () => {
    const response = await updatePut(`/private/teachers/${selectedItem.id}`, {
      ...selectedItem,
      status: false,
    });
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setListLanguages((prevDatas) =>
        prevDatas.filter((pre) => pre.id !== selectedItem.id)
      );
      showToast("Xóa thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }

    setShowDeleteModal(false);
  };

  useEffect(() => {
    const fetchListLanguages = async () => {
      const data = await fetchList("/public/teachers");
      setListLanguages(data);
    };
    fetchListLanguages();
  }, []);

  return (
    <div style={{ padding: "10px 0" }}>
      <h4>Quản lý giáo viên</h4>
      <Button
        variant="primary"
        className="mb-2"
        onClick={handleShowCreateModal}
      >
        Thêm mới
      </Button>
      <DataTable1 data={listLanguages} columns={columns} />
      <CreateLanguageModal
        show={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        handleCreate={handleCreate}
      />
      <EditLanguageModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        language={selectedItem}
        handleUpdate={handleUpdate}
      />
      <DeleteConfirmModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default ManageLanguage;
