import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import MasterCategory from "../../components/MasterCategory";
import { SiZalo } from "react-icons/si";
import { fetchList } from "../../services";
import { TailSpin } from "react-loader-spinner";
import { useWindowWidth } from "../../context/WindowWidthContext";
import { useGlobalState } from "../../context/GlobalContext";

const styles = {
  contentMasterCategory: (isMobile) => ({
    backgroundColor: "#fff",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    padding: !isMobile ? "10px 30px" : "10px",
    margin: !isMobile ? "10px 20px 0 20px" : "10px 0 0 0",
    flex: 1,
  }),
  contentMasterCategoryLoading: (isMobile) => ({
    backgroundColor: "#fff",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    padding: !isMobile ? "10px 30px" : "10px",
    margin: !isMobile ? "10px 20px 0 20px" : "10px 0 0 0",
    flex: 1,
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  contentContact: (isMobile) => ({
    margin: !isMobile ? "5px 20px 0 20px" : "5px 0px 0 0px",
    backgroundColor: "#fff",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    padding: "10px 30px",
    display: "flex",
    alignItems: "center",
  }),
};

const GreatCategory = ({ type }) => {
  const { isMobile } = useWindowWidth();
  const { state } = useGlobalState();
  const support = state.supportData;
  const [allMasterCategories, setAllMasterCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let rootDatas = [];
      if (type === "listening") {
        rootDatas = await fetchList("/public/root-categories-master-listening");
      }
      if (type === "jlpt") {
        rootDatas = await fetchList("/public/root-categories-master-jlpt");
      }
      if (type === "kaiwa") {
        rootDatas = await fetchList("/public/root-categories-master-kaiwa");
      }
      setAllMasterCategories(rootDatas);
      setLoading(false);
    };
    fetchData();
  }, [type]);

  const data = allMasterCategories[0] || {};

  return (
    <Container
      className="main-content"
      style={{
        paddingBottom: "10px",
        flexGrow: 1,
        minHeight: "calc(100vh - 60px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {loading ? (
        <div style={styles.contentMasterCategoryLoading(isMobile)}>
          <TailSpin color="green" radius={"8px"} />
        </div>
      ) : (
        <div style={styles.contentMasterCategory(isMobile)}>
          <div
            style={{
              padding: !isMobile ? "20px" : "10px",
              borderRadius: "10px",
              boxShadow:
                " 2px 2px 5px rgba(0, 0, 0, 0.3), -2px -2px 5px rgba(0, 0, 0, 0.2)",
            }}
          >
            <div className="d-flex" style={{ flexDirection: "row" }}>
              <div className="d-flex" style={{ flexDirection: "column" }}>
                <div className="d-flex">
                  <div
                    style={{
                      width: "10px",
                      border: "1px solid #fff",
                      borderRadius: "5px",
                      backgroundColor: "green",
                      height: "36px",
                      marginRight: "10px",
                      marginLeft: "10px",
                    }}
                  ></div>
                  <div>
                    <div
                      style={{
                        fontSize: !isMobile ? "24px" : "18px",
                        fontWeight: "bold",
                      }}
                    >
                      {data.name}
                    </div>
                    <div
                      style={{
                        fontSize: !isMobile ? "17px" : "15px",
                        fontWeight: "bold",
                      }}
                    >
                      {data.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {allMasterCategories.map((masterCategory) => (
            <div style={{ marginTop: "10px" }}>
              <MasterCategory
                masterCategory={masterCategory}
                hideHeader={true}
              />
            </div>
          ))}
        </div>
      )}

      <div style={styles.contentContact(isMobile)}>
        <SiZalo
          style={{ marginRight: "10px", color: "blue", fontSize: "30px" }}
        />
        <div style={{ fontWeight: "bold" }}>
          {support.name && support.phone
            ? `${support.name}: ${support.phone}`
            : "Ms. Yến: 0978365115"}
        </div>
      </div>
    </Container>
  );
};

export default GreatCategory;
