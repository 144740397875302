/* eslint-disable react/prop-types */
// GlobalStateContext.js
import { createContext, useContext, useReducer, useEffect } from "react";
import { fetchList } from "../services/index";

// Create Context
const GlobalStateContext = createContext();

// Initial state
const initialState = {
  popupData: [],
  supportData: {},
  categories: [],
  loading: false,
};

// Reducer function to handle state changes
const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_SUPPORT_DATA":
      return { ...state, supportData: action.payload };
    case "UPDATE_POPUP_DATA":
      return { ...state, popupData: action.payload };
    case "UPDATE_LIST_CATEGORY":
      return { ...state, categories: action.payload };
    default:
      return state;
  }
};

// Provider component
export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const loadSupportData = async () => {
      const supportData = await fetchList(`/public/banners/support`);
      const popupData = await fetchList(`/public/banners/popup`);
      if (supportData) {
        dispatch({
          type: "UPDATE_SUPPORT_DATA",
          payload: supportData,
        });
      }
      if (popupData) {
        dispatch({
          type: "UPDATE_POPUP_DATA",
          payload: popupData,
        });
      }
    };

    loadSupportData();
  }, []);
  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

// Custom hook to use the GlobalStateContext
export const useGlobalState = () => useContext(GlobalStateContext);
