import React, { createContext, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const showToast = (message, options) => {
    toast(message, options);
  };

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <ToastContainer autoClose={3000} position="top-right" theme="colored" />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
