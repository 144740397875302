import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SquareBox = ({ imageUrl, name, description, url }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const styles = {
    rootBox: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      cursor: "pointer",
    },
    boxStyle: {
      width: width < 768 ? "110px" : "200px",
      height: width < 768 ? "110px" : "190px",
      overflow: "hidden",
      position: "relative",
      border: "1px solid #fff",
      borderRadius: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      margin: "5px 0",
      boxShadow: "0 4px 15px rgba(0, 0, 0, 0.5)",
      background: "rgb(255, 255, 255)",
    },
    imageStyle: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      position: "absolute",

      top: width < 768 ? "-10px" : 0,
      left: 0,
    },
    textWrapper: {
      position: "absolute",
      zIndex: 1,
      color: "#000",
      bottom: "10px",
      width: "100%",
    },
    name: {
      backgroundColor: "#16a553",
      margin: 0,
      color: "#fff",
      fontWeight: "bold",
      textTransform: "uppercase",
      padding: width < 768 ? "2px 0" : "5px 0",
      fontSize: width < 768 ? "12px" : "16px",
      width: "70%",
    },
    description: {
      color: "#16a553",
      marginTop: "5px",
      marginBottom: 0,
      fontSize: width < 768 ? "10px" : "14px",
      fontWeight: "600",
      letterSpacing: width < 768 ? "-1px" : "normal",
    },
  };

  return (
    <div
      style={styles.rootBox}
      onClick={() => {
        navigate(url);
      }}
    >
      <div style={styles.boxStyle}>
        <img src={imageUrl} alt="Square" style={styles.imageStyle} />
        <div style={styles.textWrapper}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p style={styles.name}>{name} </p>
          </div>
          <p style={styles.description}>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default SquareBox;
