import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import httpService from "../../../../utils/request";
import { useToast } from "../../../../context/ToastContext";
import { DOMAIN } from "../../../../constants";
import { RotatingLines } from "react-loader-spinner";

const languageSchema = z.object({
  bankName: z.string().min(1, "Tên ngân hàng là bắt buộc!"),
  accountName: z.string().min(1, "Chủ tài khoản là bắt buộc!"),
  bankNumber: z.string().min(1, "Số tài khoản là bắt buộc!"),
  type: z.string().min(1, "Loại Popup là bắt buộc!"),
  isActive: z.boolean(),
});

const OPTIONS = [
  {
    id: "BANK_VN",
    name: "Ngân Hàng Việt Nam",
  },
  {
    id: "BANK_JP",
    name: "Ngân Hàng Nhật Bản",
  },
];

const EditLanguageModal = ({ show, handleClose, language, handleUpdate }) => {
  const showToast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm({
    defaultValues: language,
    resolver: zodResolver(languageSchema),
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const imageFile = watch("image");

  const isActive = watch("isActive");

  useEffect(() => {
    if (imageFile && imageFile.length > 0) {
      const file = imageFile[0];
      if (file && file instanceof File) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setImagePreview(null);
    }
  }, [imageFile]);

  useEffect(() => {
    if (language) {
      reset(language);
      language?.image && setImagePreview(`${DOMAIN}${language?.image}`);
    }
  }, [language, reset]);

  const onSubmit = async (data) => {
    try {
      try {
        setLoading(true);
        let imageData = imagePreview;
        if (imagePreview && imagePreview.includes("base64")) {
          imageData = await httpService.post("/private/upload", {
            image: imagePreview,
          });
        }
        const dataResquest = {
          ...data,
          image: imageData.replace(DOMAIN, ""),
        };
        const response = await httpService.put(
          `/private/banks/${language.id}`,
          dataResquest
        );
        handleUpdate({ id: response.id, ...dataResquest });
        setLoading(false);
        showToast("Chỉnh sửa thành công", { type: "success" });
        reset();
        setImagePreview(null);
      } catch (error) {
        showToast(error.response.data, {
          position: "top-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      showToast("Có lỗi xảy ra", { type: "error" });
    }
  };

  const handleCloseModal = () => {
    reset();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>Chỉnh sửa ảnh banner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="bankName">
            <Form.Label>
              Tên Ngân Hàng <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              {...register("bankName")}
              isInvalid={!!errors.bankName}
            />
            {errors.bankName && (
              <Form.Control.Feedback type="invalid">
                {errors.bankName.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group controlId="accountName">
            <Form.Label>
              Chủ Tài Khoản <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              {...register("accountName")}
              isInvalid={!!errors.accountName}
            />
            {errors.accountName && (
              <Form.Control.Feedback type="invalid">
                {errors.accountName.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group controlId="bankNumber">
            <Form.Label>
              Số Tài Khoản <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              {...register("bankNumber")}
              isInvalid={!!errors.bankNumber}
            />
            {errors.bankNumber && (
              <Form.Control.Feedback type="invalid">
                {errors.bankNumber.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group controlId="courseCategoryImage">
            <Form.Label>
              Ảnh <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              isInvalid={!!errors.image}
              type="file"
              accept="image/*"
              {...register("image", { required: "File is required" })}
            />
            {imagePreview && (
              <div className="mt-3">
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{
                    width: "160px",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              </div>
            )}
            {errors.image && (
              <Form.Control.Feedback type="invalid">
                {errors.image.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group controlId="type">
            <Form.Label>Thanh Toán Tại Ngân Hàng</Form.Label>
            <Form.Control
              as="select"
              {...register("type")}
              isInvalid={!!errors.type}
            >
              <option value="">Chọn loại ngân hàng</option>
              {(OPTIONS || []).map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
            {errors.type && (
              <Form.Control.Feedback type="invalid">
                {errors.type.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group controlId="isActive" className="mt-2 d-flex gap-2">
            <Form.Label>Trạng thái active</Form.Label>
            <Form.Check
              type="switch"
              {...register("isActive")}
              checked={!!isActive}
              onChange={(e) => setValue("isActive", e.target.checked)}
            />
          </Form.Group>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Đóng
            </Button>
            <Button variant="primary" type="submit">
              Cập nhật
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
      {loading && (
        <div style={{ position: "absolute", top: "30%", left: "40%" }}>
          <RotatingLines color="green" radius={"8px"} />
        </div>
      )}
    </Modal>
  );
};

export default EditLanguageModal;
