// ContactFooter.js
import React from "react";
import { FaFacebook } from "react-icons/fa"; // Assuming you use a comments icon for Zalo
import "./styles/ContactFooter.css";
import image2 from "../assets/images/icon-zalo.png";
import { useWindowWidth } from "../context/WindowWidthContext";

const ContactFooter = ({ support }) => {
  const { isMobile } = useWindowWidth();
  return (
    <div
      className={
        isMobile ? "contact-footer contact-footer-mobile" : "contact-footer"
      }
    >
      <a
        href={support.fbUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="circle-button"
        style={{ cursor: "pointer" }}
      >
        <FaFacebook className="contact-image" size={!isMobile ? 28 : 20} />
      </a>
      <a
        href={support.zaloUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="circle-button"
        style={{ cursor: "pointer" }}
      >
        <img
          className="contact-image"
          style={{
            width: !isMobile ? "28px" : "20px",
            height: "auto",
            objectFit: "cover",
          }}
          src={image2}
          alt=""
        />
      </a>
      <a
        href={support.paymentUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="payment-button"
        style={{ cursor: "pointer" }}
      >
        Hướng dẫn
      </a>
    </div>
  );
};

export default ContactFooter;
