import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import httpService from "../../../../utils/request";
import { useToast } from "../../../../context/ToastContext";

const languageSchema = z.object({
  password: z.string().min(1, "Mật khẩu là bắt buộc"),
});

const EditLanguageModal = ({ show, handleClose, language, handleUpdate }) => {
  const showToast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: language,
    resolver: zodResolver(languageSchema),
  });

  const onSubmit = async (data) => {
    try {
      await httpService.put(`/private/users/password/${language.id}`, {
        password: data.password,
      });
      handleUpdate({ id: language.id, ...data });
      showToast("Đổi mật khẩu thành công", {
        type: "success",
      });
      reset();
    } catch (error) {
      showToast("Có lỗi xảy ra! Chỉnh sửa không thành công", {
        type: "error",
      });
    }
  };

  const handleCloseModal = () => {
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>Thay đổi mật khẩu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="password">
            <Form.Label>
              Mật khẩu <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              {...register("password")}
              isInvalid={!!errors.password}
            />
            {errors.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Đóng
            </Button>
            <Button variant="primary" type="submit">
              Cập nhật
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditLanguageModal;
