import React, { createContext, useState, useEffect, useContext } from "react";

// Create a context
const WindowWidthContext = createContext();

// Create a provider component
export const WindowWidthProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);

  const isMobile = width <= 768;

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <WindowWidthContext.Provider value={{ width, isMobile }}>
      {children}
    </WindowWidthContext.Provider>
  );
};

// Custom hook to use the WindowWidthContext
export const useWindowWidth = () => {
  return useContext(WindowWidthContext);
};
