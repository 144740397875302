import httpService from "../utils/request";

export const fetchList = async (url) => {
  try {
    const response = await httpService.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const create = async (url, data) => {
  try {
    const response = await httpService.post(url, data);
    return response;
  } catch (error) {
    return {
      error: true,
      status: error.status,
      message: error.message,
    };
  }
};

export const updatePatch = async (url, data) => {
  try {
    const response = await httpService.patch(url, data);
    return response;
  } catch (error) {
    return {
      error: true,
      status: error.status,
      message: error.message,
    };
  }
};

export const updatePut = async (url, data) => {
  try {
    const response = await httpService.put(url, data);
    return response;
  } catch (error) {
    return {
      error: true,
      status: error.status,
      message: error.message,
    };
  }
};
