import React, { useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const courseCategorySchema = z.object({
  open: z.boolean().optional(),
  name: z.string().min(1, "Tên danh mục là bắt buộc"),
  url: z.string().optional(),
  description: z.string().optional(),
  documentUrl: z.string().optional().nullable(),
  partCategoryId: z.union([
    z.string().min(1, "Danh mục là bắt buộc"),
    z.number().min(1, "Danh mục là bắt buộc"),
  ]),
  teacherId: z.union([
    z.string().min(1, "Danh mục là bắt buộc"),
    z.number().min(1, "Danh mục là bắt buộc"),
  ]),
});

const CreateCategoryModal = ({
  show,
  handleClose,
  handleCreate,
  parents,
  categoryId,
  teachers,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: zodResolver(courseCategorySchema),
  });

  useEffect(() => {
    if (categoryId) {
      setValue("partCategoryId", categoryId);
    }
  }, [categoryId, setValue, show]);

  const onSubmit = (data) => {
    handleCreate({ ...data });
    handleClose();
    reset();
  };

  const handleCloseModal = () => {
    reset();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Thêm mới bài học</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "600px", overflow: "auto" }}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="courseCategoryName">
            <Form.Label>Tên bài học</Form.Label>
            <Form.Control
              type="text"
              {...register("name")}
              isInvalid={!!errors.name}
            />
            {errors.name && (
              <Form.Control.Feedback type="invalid">
                {errors.name.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="rootCategoryDescription">
            <Form.Label>Mô tả</Form.Label>
            <Form.Control
              type="text"
              {...register("description")}
              isInvalid={!!errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {errors.description?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="documentUrl">
            <Form.Label>Link Tài Liệu</Form.Label>
            <Form.Control
              type="text"
              {...register("documentUrl")}
              isInvalid={!!errors.documentUrl}
            />
            <Form.Control.Feedback type="invalid">
              {errors.documentUrl?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="url">
            <Form.Label>Link Youtube</Form.Label>
            <Form.Control
              type="text"
              {...register("url")}
              isInvalid={!!errors.url}
            />
            <Form.Control.Feedback type="invalid">
              {errors.url?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="teacherId">
            <Form.Label>Danh mục cha</Form.Label>
            <Form.Control
              as="select"
              {...register("teacherId")}
              isInvalid={!!errors.teacherId}
            >
              <option value="">Chọn giáo viên</option>
              {(teachers || []).map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
            {errors.teacherId && (
              <Form.Control.Feedback type="invalid">
                {errors.teacherId.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group controlId="partCategoryId">
            <Form.Label>Danh mục cha</Form.Label>
            <Form.Control
              as="select"
              {...register("partCategoryId")}
              isInvalid={!!errors.partCategoryId}
            >
              <option value="">Chọn danh mục</option>
              {(parents || []).map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
            {errors.partCategoryId && (
              <Form.Control.Feedback type="invalid">
                {errors.partCategoryId.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group controlId="open" style={{ marginTop: "10px" }}>
            <Form.Check
              type="checkbox"
              {...register("open")}
              label="Bài học cần đăng nhập không"
              isInvalid={!!errors.open}
            />
            {errors.open && (
              <Form.Control.Feedback type="invalid">
                {errors.open.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Modal.Footer style={{ marginTop: "15px" }}>
            <Button variant="secondary" onClick={handleCloseModal}>
              Đóng
            </Button>
            <Button variant="primary" type="submit">
              Thêm mới
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateCategoryModal;
