import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import httpService from "../../../../utils/request";
import { RotatingLines } from "react-loader-spinner";
import { DOMAIN } from "../../../../constants";
import { getImage } from "../../../../utils";

const rootCategorySchema = z.object({
  name: z.string().min(1, "Tên danh mục là bắt buộc"),
  description: z.string().min(1, "Mô tả là bắt buộc"),
  view: z.union([z.string().optional().nullable(), z.number().optional()]),
  like: z.union([z.string().optional().nullable(), z.number().optional()]),
  disLike: z.union([z.string().optional().nullable(), z.number().optional()]),
  masterCategoryId: z.union([
    z.string().min(1, "Danh mục là bắt buộc"),
    z.number().min(1, "Danh mục là bắt buộc"),
  ]),
});

const EditCategoryModal = ({
  show,
  handleClose,
  rootCategory,
  handleUpdate,
  parents,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: rootCategory,
    resolver: zodResolver(rootCategorySchema),
  });

  useEffect(() => {
    if (rootCategory) {
      reset(rootCategory);
      rootCategory?.image && setImagePreview(getImage(rootCategory?.image));
    }
  }, [rootCategory, reset]);

  const [imagePreview, setImagePreview] = useState(null);
  const imageFile = watch("image");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (imageFile && imageFile.length > 0) {
      const file = imageFile[0];
      if (file && file instanceof File) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setImagePreview(null);
    }
  }, [imageFile]);

  const onSubmit = async (data) => {
    let imageData = imagePreview;
    setLoading(true);
    if (imagePreview && imagePreview.includes("base64")) {
      imageData = await httpService.post("/private/upload", {
        image: imagePreview,
      });
    }
    handleUpdate({
      ...rootCategory,
      ...data,
      image: imageData.replace(DOMAIN, ""),
    });
    handleClose();
    reset();
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Chỉnh sửa danh mục gốc</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ position: "relative" }}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="rootCategoryName">
            <Form.Label>Tên danh mục</Form.Label>
            <Form.Control
              type="text"
              {...register("name")}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="rootCategoryDescription">
            <Form.Label>Mô tả</Form.Label>
            <Form.Control
              type="text"
              {...register("description")}
              isInvalid={!!errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {errors.description?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="view">
            <Form.Label>Số View</Form.Label>
            <Form.Control
              type="number"
              {...register("view")}
              isInvalid={!!errors.view}
            />
            <Form.Control.Feedback type="invalid">
              {errors.view?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="like">
            <Form.Label>Số Like</Form.Label>
            <Form.Control
              type="number"
              {...register("like")}
              isInvalid={!!errors.like}
            />
            <Form.Control.Feedback type="invalid">
              {errors.like?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="disLike">
            <Form.Label>Số DisLike</Form.Label>
            <Form.Control
              type="number"
              {...register("disLike")}
              isInvalid={!!errors.disLike}
            />
            <Form.Control.Feedback type="invalid">
              {errors.disLike?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="masterCategoryId">
            <Form.Label>Danh mục cha</Form.Label>
            <Form.Control
              as="select"
              {...register("masterCategoryId")}
              isInvalid={!!errors.masterCategoryId}
            >
              <option value="">Chọn danh mục</option>
              {(parents || []).map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
            {errors.masterCategoryId && (
              <Form.Control.Feedback type="invalid">
                {errors.masterCategoryId.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group controlId="courseCategoryImage">
            <Form.Label>Ảnh</Form.Label>
            <Form.Control
              isInvalid={!!errors.image}
              type="file"
              accept="image/*"
              {...register("image", { required: "File is required" })}
            />
            {imagePreview && (
              <div className="mt-3">
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{
                    width: "160px",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              </div>
            )}
            {errors.image && (
              <Form.Control.Feedback type="invalid">
                {errors.image.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Đóng
            </Button>
            <Button variant="primary" type="submit">
              Cập nhật
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
      {loading && (
        <div style={{ position: "absolute", top: "30%", left: "40%" }}>
          <RotatingLines color="green" radius={"8px"} />
        </div>
      )}
    </Modal>
  );
};

export default EditCategoryModal;
