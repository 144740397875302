import React, { useState, useEffect } from "react";
import { AiTwotoneLike } from "react-icons/ai";
import { AiTwotoneDislike } from "react-icons/ai";
import { Row, Col } from "react-bootstrap";
import image1 from "../assets/images/play.png";
import image2 from "../assets/images/luot_xem.png";
import { fetchList } from "../services";
import { useNavigate } from "react-router-dom";
import { getImage } from "../utils";
import { useWindowWidth } from "../context/WindowWidthContext";

const Item = ({ category }) => {
  const { isMobile } = useWindowWidth();
  const navigate = useNavigate();
  const [view, setView] = useState(0);
  const [like, setLike] = useState(0);
  const [disLike, setDisLike] = useState(0);

  const { id, view: dataView, like: dataLike, disLike: dataDisLike } = category;

  useEffect(() => {
    setView(dataView);
    setLike(dataLike);
    setDisLike(dataDisLike);
  }, [dataView, dataLike, dataDisLike]);

  const handleView = async () => {
    const rootDatas = await fetchList(
      `/public/master-categories/type?id=${id}&type=1`
    );
    rootDatas && setView((prev) => prev + 1);
    navigate(`/sub-category/${id}`);
  };
  const handLike = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const rootDatas = await fetchList(
      `/public/master-categories/type?id=${id}&type=2`
    );
    rootDatas && setLike((prev) => prev + 1);
  };
  const handDisLike = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const rootDatas = await fetchList(
      `/public/master-categories/type?id=${id}&type=3`
    );
    rootDatas && setDisLike((prev) => prev + 1);
  };
  return (
    <div
      className="d-flex"
      onClick={handleView}
      style={{
        width: "275px",
        flexDirection: "column",
        boxShadow:
          "4px 4px 10px rgba(0, 0, 0, 0.3), -4px -4px 10px rgba(0, 0, 0, 0.2)",
        borderRadius: "7px",
        margin: "5px 0",
        padding: !isMobile ? "10px" : "5px",
        cursor: "pointer",
      }}
    >
      <div style={{ width: "100%" }}>
        <img
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
            borderRadius: "5px",
          }}
          src={getImage(category.image)}
          alt=""
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "5px",
          alignItems: "flex-start",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <div
          style={{
            fontSize: !isMobile ? "16px" : "13px",
            fontWeight: "bold",
            padding: "5px 0",
            color: "blue",
            textAlign: "left",
          }}
        >
          {category.name}
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              fontSize: !isMobile ? "14px" : "12px",
              padding: "5px 0",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ width: !isMobile ? "14px" : "11px" }}>
              <img
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
                src={image1}
                alt=""
              />
            </div>
            <div
              style={{
                marginLeft: !isMobile ? "5px" : "2px",
                color: "#000",
                fontWeight: 500,
                letterSpacing: !isMobile ? "normal" : "-1px",
              }}
            >
              {!isMobile && "Bao gồm: "}
              <span style={{ color: "blue" }}>{category.totalPart}</span> Bài
              giảng,{" "}
              <span style={{ color: "blue" }}>{category.totalVideos}</span>{" "}
              Videos
            </div>
          </div>
          <div
            style={{
              fontSize: !isMobile ? "14px" : "11px",
              display: "flex",
              flexDirection: !isMobile ? "row" : "column",
              alignItems: !isMobile ? "center" : "flex-start",
              justifyContent: "space-between",
              width: "100%",
              color: "#000",
              fontWeight: 500,
              flex: 1,
            }}
          >
            <div
              style={{
                fontSize: !isMobile ? "14px" : "11px",
                padding: "5px 0",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ width: !isMobile ? "14px" : "11px" }}>
                <img
                  style={{ width: "100%", height: "auto", objectFit: "cover" }}
                  src={image2}
                  alt=""
                />
              </div>
              <div style={{ marginLeft: !isMobile ? "5px" : "2px" }}>
                {!isMobile ? `Lượt xem: ${view} (View)` : `${view} (View)`}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#ddd",
                padding: "5px 5px",
                borderRadius: "10px",
                fontSize: !isMobile ? "14px" : "12px",
                width: "fit-content",
              }}
            >
              <div
                onClick={handLike}
                style={{ display: "flex", alignItems: "center" }}
              >
                <AiTwotoneLike />
                <div style={{ marginLeft: "2px" }}>{like}</div>
              </div>
              <div
                onClick={handDisLike}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "3px",
                }}
              >
                <AiTwotoneDislike />
                <div style={{ marginLeft: "2px" }}>{disLike}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CourseList = ({ categories }) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {categories.length <= 0 ? (
        <div style={{ fontSize: "18px", color: "red" }}>
          Khóa học đang cập nhật!
        </div>
      ) : (
        <Row style={{ width: "100%" }}>
          {categories.map((category) => (
            <Col
              key={category.id}
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
              md={4}
              xs={6}
              sm={6}
            >
              <Item category={category} />
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default CourseList;
