import React, { useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const rootCategorySchema = z.object({
  open: z.boolean().optional(),
  name: z.string().min(1, "Tên danh mục là bắt buộc"),
  url: z.string().min(1, "Link là bắt buộc"),
  time: z.string().min(1, "Thời gian là bắt buộc"),
  description: z.string().optional(),
  documentUrl: z.string().optional().nullable(),
  masterCategoryId: z.union([
    z.string().min(1, "Danh mục là bắt buộc"),
    z.number().min(1, "Danh mục là bắt buộc"),
  ]),
});

const EditCategoryModal = ({
  show,
  handleClose,
  rootCategory,
  handleUpdate,
  parents,
  categoryId,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: rootCategory,
    resolver: zodResolver(rootCategorySchema),
  });

  useEffect(() => {
    if (categoryId) {
      setValue("masterCategoryId", categoryId);
    }
  }, [categoryId, setValue]);

  useEffect(() => {
    if (rootCategory) {
      reset(rootCategory);
    }
  }, [rootCategory, reset]);

  const onSubmit = (data) => {
    handleUpdate({ ...rootCategory, ...data });
    handleClose();
    reset();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Chỉnh sửa bài học hay</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="rootCategoryName">
            <Form.Label>Tên bài học</Form.Label>
            <Form.Control
              type="text"
              {...register("name")}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="rootCategoryDescription">
            <Form.Label>Mô tả</Form.Label>
            <Form.Control
              type="text"
              {...register("description")}
              isInvalid={!!errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {errors.description?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="documentUrl">
            <Form.Label>Link Tài Liệu</Form.Label>
            <Form.Control
              type="text"
              {...register("documentUrl")}
              isInvalid={!!errors.documentUrl}
            />
            <Form.Control.Feedback type="invalid">
              {errors.documentUrl?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="url">
            <Form.Label>Link</Form.Label>
            <Form.Control
              type="text"
              {...register("url")}
              isInvalid={!!errors.url}
            />
            <Form.Control.Feedback type="invalid">
              {errors.url?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="time">
            <Form.Label>Thời gian video</Form.Label>
            <Form.Control
              type="text"
              {...register("time")}
              isInvalid={!!errors.time}
            />
            <Form.Control.Feedback type="invalid">
              {errors.time?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="masterCategoryId">
            <Form.Label>Danh mục cha</Form.Label>
            <Form.Control
              as="select"
              {...register("masterCategoryId")}
              isInvalid={!!errors.masterCategoryId}
            >
              <option value="">Chọn danh mục</option>
              {(parents || []).map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
            {errors.masterCategoryId && (
              <Form.Control.Feedback type="invalid">
                {errors.masterCategoryId.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group controlId="open" style={{ marginTop: "10px" }}>
            <Form.Check
              type="checkbox"
              {...register("open")}
              label="Khóa bài học cần đăng nhập"
              isInvalid={!!errors.open}
            />
            {errors.open && (
              <Form.Control.Feedback type="invalid">
                {errors.open.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Modal.Footer style={{ marginTop: "15px" }}>
            <Button variant="secondary" onClick={handleClose}>
              Đóng
            </Button>
            <Button variant="primary" type="submit">
              Cập nhật
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditCategoryModal;
