import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import SessionMenu from "../components/SessionMenu";
import SessionMenuDescription from "../components/SessionMenuDescription";
import MasterCategory from "../components/MasterCategory";
import MasterCategoryUpdate from "../components/MasterCategoryUpdate";
import TitlePrimary from "../components/TitlePrimary";
import { SiZalo } from "react-icons/si";
import Diamond from "../components/Diamond";
import { fetchList } from "../services";
import { TailSpin } from "react-loader-spinner";
import ContactFooter from "../components/ContactFooter";
import { useWindowWidth } from "../context/WindowWidthContext";

const Home = () => {
  const { isMobile } = useWindowWidth();
  const [allMasterCategories, setAllMasterCategories] = useState([]);
  const [categoryUpdate, setCategoryUpdate] = useState([]);
  // const [teachers, setTeachers] = useState([]);
  const [posters, setPosters] = useState([]);
  const [support, setSupport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const styles = {
    contentMasterCategory: (isMobile) => ({
      backgroundColor: "#fff",
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
      padding: !isMobile ? "20px 30px" : "10px 10px",
      margin: !isMobile ? "0 20px" : 0,
    }),
    contentMasterCategoryLoading: (isMobile) => ({
      backgroundColor: "#fff",
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
      padding: !isMobile ? "20px 30px" : "10px 10px",
      margin: !isMobile ? "0 20px" : 0,
      height: "300px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }),
    contentContact: (isMobile) => ({
      margin: !isMobile ? "5px 20px 0 20px" : "5px 0px 0 0px",
      backgroundColor: "#fff",
      borderBottomLeftRadius: "20px",
      borderBottomRightRadius: "20px",
      padding: "10px 30px",
      display: "flex",
      alignItems: "center",
    }),
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const rootDatas = await fetchList("/public/root-categories-master");
      // const dataTeachers = await fetchList(`/public/teachers`);
      const settingDatas = await fetchList(`/public/banners`);
      if (settingDatas) {
        const dataImages = settingDatas.filter((x) => x.type === "BANNER");
        const dataSupport =
          settingDatas.find((x) => x.type === "SUPPORT") || {};
        setPosters(dataImages);
        setSupport(dataSupport);
      }
      setAllMasterCategories(rootDatas);
      // setTeachers(dataTeachers);
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(true);
      const rootDatas = await fetchList(
        "/public/root-categories-master-update"
      );
      if (rootDatas && rootDatas.length > 0) {
        setCategoryUpdate(rootDatas[0]);
      }
      setLoadingData(false);
    };
    fetchData();
  }, []);

  return (
    <div style={{ paddingBottom: "10px" }}>
      <Container className={isMobile ? "mobile-container" : ""}>
        <SessionMenu />
      </Container>

      <Container
        className={isMobile ? "mobile-container" : ""}
        style={{ marginTop: "10px" }}
      >
        {loading || loadingData ? (
          <div style={styles.contentMasterCategoryLoading(isMobile)}>
            <TailSpin color="green" radius={"8px"} />
          </div>
        ) : (
          <div style={styles.contentMasterCategory(isMobile)}>
            {posters.length > 0 && <SessionMenuDescription posters={posters} />}
            {/* {teachers.length > 0 && <Diamond teachers={teachers} />} */}
            {allMasterCategories.map((masterCategory, index) => (
              <MasterCategory key={index} masterCategory={masterCategory} />
            ))}
            <div style={{ padding: "20px 0 20px 0" }}>
              {(categoryUpdate.masterCategories || []).length > 0 && (
                <>
                  <TitlePrimary
                    color={"purple"}
                    name={categoryUpdate.name}
                    description={categoryUpdate.description}
                  />
                  <MasterCategoryUpdate
                    masterCategories={categoryUpdate.masterCategories || []}
                  />
                </>
              )}
            </div>
          </div>
        )}

        <div style={styles.contentContact(isMobile)}>
          <SiZalo
            style={{ marginRight: "10px", color: "blue", fontSize: "30px" }}
          />
          <div style={{ fontWeight: "bold" }}>
            {support.name && support.phone
              ? `${support.name}: ${support.phone}`
              : "Ms. Yến: 0978365115"}
          </div>
        </div>
      </Container>

      <ContactFooter support={support} />
    </div>
  );
};

export default Home;
