import { Button } from "react-bootstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { RiLockPasswordLine } from "react-icons/ri";

export const EditButton = ({ onClick }) => {
  return (
    <Button
      variant="warning"
      size="sm"
      onClick={onClick}
      className="d-flex flex-row align-items-center py-2"
    >
      <FaEdit color="#fff" size={18} />
    </Button>
  );
};

export const ChangePassword = ({ onClick }) => {
  return (
    <Button
      variant="warning"
      size="sm"
      onClick={onClick}
      className="d-flex flex-row align-items-center py-2"
    >
      <RiLockPasswordLine color="#fff" size={18} />
    </Button>
  );
};

export const DeleteButton = ({ onClick }) => {
  return (
    <Button
      variant="danger"
      size="sm"
      onClick={onClick}
      className="d-flex flex-row align-items-center  py-2"
    >
      <FaTrashAlt color="#fff" size={18} />
    </Button>
  );
};
