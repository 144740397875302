import React from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import { Table } from "react-bootstrap";
import "./styles/table.css";

function Filter({ column, table }) {
  const { isFilter, options } = column.columnDef;
  if (!isFilter) return null;
  const columnFilterValue = column.getFilterValue();

  const type = column.columnDef.type;

  if (type === "select") {
    return (
      <select
        style={{ width: "100%" }}
        value={columnFilterValue}
        onChange={(e) => {
          column.setFilterValue(
            e.target.value !== "" && e.target.value !== null
              ? e.target.value
              : undefined
          );
        }}
      >
        <option value="">All</option>

        {options.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    );
  }

  return (
    <input
      style={{ width: "100%" }}
      type="text"
      value={columnFilterValue ?? ""}
      onChange={(e) => {
        column.setFilterValue(e.target.value);
      }}
      placeholder={`Search...`}
      className="w-36 border shadow rounded"
    />
  );
}

const DataTable = ({ data, columns }) => {
  const table = useReactTable({
    data,
    columns,
    filterFns: {
      includesString: (row, columnId, filterValue) => {
        const rowValue = String(row.getValue(columnId) || "");
        return rowValue.includes(filterValue); // Perform the "like" search
      },
      equals: (row, columnId, filterValue) => {
        const rowValue = row.getValue(columnId);
        return rowValue == filterValue; // Check for equality
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div>
      <Table bordered hover>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.column.getCanFilter() ? (
                        <div>
                          <Filter column={header.column} table={table} />
                        </div>
                      ) : null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="wrap-show-page">
          <div className="flex items-center gap-1 show-per-page">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </div>
          <div className="d-flex">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <span
                className="flex items-center gap-1"
                style={{ marginLeft: "5px" }}
              >
                Go to page:
              </span>
              <input
                style={{ marginLeft: "5px" }}
                type="number"
                min="1"
                max={table.getPageCount()}
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
                className="border p-1 rounded w-16"
              />
            </div>
            <select
              style={{ marginLeft: "10px" }}
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex items-center gap-1 pagination">
          <button
            className="border rounded"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {"<<"}
          </button>
          <button
            className="border rounded"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {"<"}
          </button>
          {[0, 1, 2].map((page, index) => (
            <button
              key={index}
              onClick={() => table.setPageIndex(page)}
              className={
                table.getState().pagination.pageIndex === page ? "active" : ""
              }
            >
              {page + 1}
            </button>
          ))}
          <button
            className="border rounded"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {">"}
          </button>
          <button
            className="border rounded"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DataTable;
