import React, { useState, useEffect } from "react";
import DataTable1 from "../../components/admin/DataTable";
import CreateRootCategoryModal from "../../components/admin/modals/root-category/CreateRootCategory";
import EditRootCategoryModal from "../../components/admin/modals/root-category/EditRootCategory";
import { FaEdit, FaTrash } from "react-icons/fa";
import DeleteConfirmModal from "../../components/admin/modals/root-category/DeleteRootCategory";
import { Accordion, Dropdown, ButtonGroup, Button } from "react-bootstrap";
import CourseCategory from "./CourseCategory";
import CategoryLV3 from "./Category";
import CategoryLV4 from "./Part";
import CategoryLV5 from "./Course";
import CategoryLV6 from "./CourseUpdate";
import "./home.css";
import { fetchList, create, updatePut } from "../../services";
import { useToast } from "../../context/ToastContext";

const HomeAdmin = () => {
  const showToast = useToast();
  const [language, setLanguage] = useState([]);
  const [rootData, setRootData] = useState([]);
  const [rootDataA, setRootDataA] = useState([]);
  const [rootDataB, setRootDataB] = useState([]);
  const [rootDataC, setRootDataC] = useState([]);
  const [rootDataD, setRootDataD] = useState([]);
  const [rootDataU, setRootDataU] = useState([]);

  const [selectIdRoot, setSelectIdRoot] = useState(null);
  const [selectIdRootA, setSelectIdRootA] = useState(null);
  const [selectIdRootB, setSelectIdRootB] = useState(null);
  const [selectIdRootC, setSelectIdRootC] = useState(null);
  const [selectIdRootUpdate, setSelectIdRootUpdate] = useState(null);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [teachers, setTeachers] = useState({});

  const [openItems, setOpenItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const rootDatas = await fetchList("/public/root-categories");
      const languages = await fetchList("/public/languages");
      const teacherDatas = await fetchList(`/public/teachers`);
      if (rootDatas) {
        setRootData(
          rootDatas.map((item, index) => ({
            stt: index + 1,
            id: item.id,
            name: item.name,
            status: item.status,
            description: item.description,
            rootLanguageId: item.rootLanguageId,
            isUpdateCategory: item.isUpdateCategory || false,
            isGreatCategory: item.isGreatCategory || false,
            isListeningCategory: item.isListeningCategory || false,
            isJLPT: item.isJLPT || false,
            isKaiwa: item.isKaiwa || false,
            color: item.color,
          }))
        );
      }
      if (languages) {
        setLanguage(
          languages.map((item, index) => ({
            stt: index + 1,
            id: item.id,
            name: item.name,
            description: item.description,
            code: item.code,
          }))
        );
      }
      if (teacherDatas) {
        setTeachers(
          teacherDatas.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        );
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const rootDatas = await fetchList(
        `/public/master-categories-by-parent?id=${selectIdRoot.id}`
      );
      if (rootDatas) {
        setRootDataA(
          rootDatas.map((item, index) => ({
            stt: index + 1,
            id: item.id,
            name: item.name,
            description: item.description,
            rootCategoryId: item.rootCategoryId,
            rootCategory: selectIdRoot?.name,
            image: item.image,
            order: item.order,
          }))
        );
      }
      setSelectIdRootA(null);
      setSelectIdRootB(null);
      setSelectIdRootC(null);
    };
    selectIdRoot && fetchData();
  }, [selectIdRoot]);

  useEffect(() => {
    const fetchData = async () => {
      const rootDatas = await fetchList(
        `/public/categories/search?id=${selectIdRootA.id}`
      );
      if (rootDatas) {
        setRootDataB(
          rootDatas.map((item, index) => ({
            stt: index + 1,
            id: item.id,
            name: item.name,
            description: item.description,
            masterCategoryId: item.masterCategoryId,
            masterCategory: selectIdRootA?.name,
            image: item.image,
            view: item.view,
            like: item.like,
            disLike: item.disLike,
            order: item.order,
          }))
        );
      }
      setSelectIdRootB(null);
      setSelectIdRootC(null);
    };
    selectIdRootA && fetchData();
  }, [selectIdRootA]);

  useEffect(() => {
    const fetchData = async () => {
      const rootDatas = await fetchList(
        `/public/course/by-parent-master?id=${selectIdRootUpdate.id}`
      );
      if (rootDatas) {
        setRootDataU(
          rootDatas.map((item, index) => ({
            stt: index + 1,
            id: item.id,
            name: item.name,
            url: item.url,
            documentUrl: item.documentUrl,
            time: item.time,
            description: item.description,
            masterCategoryId: item.masterCategoryId,
            teacherId: item.teacherId,
            open: item.open || false,
            masterCategory: selectIdRootUpdate?.name,
          }))
        );
      }
    };
    selectIdRootUpdate && fetchData();
  }, [selectIdRootUpdate]);

  useEffect(() => {
    const fetchData = async () => {
      const rootDatas = await fetchList(
        `/public/parts/by-parent?id=${selectIdRootB.id}`
      );
      if (rootDatas) {
        setRootDataC(
          rootDatas.map((item, index) => ({
            stt: index + 1,
            id: item.id,
            order: item.order,
            name: item.name,
            description: item.description,
            categoryId: item.categoryId,
            category: selectIdRootB?.name,
          }))
        );
      }
      setSelectIdRootC(null);
    };
    selectIdRootB && fetchData();
  }, [selectIdRootB]);

  useEffect(() => {
    const fetchData = async () => {
      const rootDatas = await fetchList(
        `/public/course/by-parent?id=${selectIdRootC.id}`
      );
      if (rootDatas) {
        setRootDataD(
          rootDatas.map((item, index) => ({
            stt: index + 1,
            id: item.id,
            name: item.name,
            url: item.url,
            description: item.description,
            documentUrl: item.documentUrl,
            partCategoryId: item.partCategoryId,
            teacherId: item.teacherId,
            open: item.open || false,
            partCategory: selectIdRootC?.name,
            order: item.order,
          }))
        );
      }
    };
    selectIdRootB && fetchData();
  }, [selectIdRootC]);

  const handleToggle = (eventKey) => {
    setOpenItems((prev) =>
      prev.includes(eventKey)
        ? prev.filter((key) => key !== eventKey)
        : [...prev, eventKey]
    );
  };

  const handleDropdownToggle = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleShowCreateModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowCreateModal(true);
  };

  const handleShowEditModal = (item) => {
    setShowEditModal(true);
    setSelectedItem(item);
  };

  const handleShowDeleteModal = (item) => {
    setShowDeleteModal(true);
    setSelectedItem(item);
  };

  const handleCreate = async (rootCategory) => {
    const newRootCategory = {
      ...rootCategory,
      rootLanguageId: +rootCategory.rootLanguageId,
    };
    const response = await create("/private/root-categories", newRootCategory);
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      const newRootCategory = {
        stt: rootData.length + 1,
        id: response.id,
        ...rootCategory,
        rootLanguageId: +rootCategory.rootLanguageId,
      };
      setRootData([...rootData, newRootCategory]);
      showToast("Thêm mới thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const handleUpdate = async (updatedRootCategory) => {
    const newRootCategory = {
      ...updatedRootCategory,
      rootLanguageId: +updatedRootCategory.rootLanguageId,
    };
    delete newRootCategory.stt;

    const response = await updatePut(
      `/private/root-categories/${updatedRootCategory.id}`,
      newRootCategory
    );
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setRootData((prevDatas) =>
        prevDatas.map((pre) =>
          pre.id === updatedRootCategory.id
            ? {
                ...pre,
                ...updatedRootCategory,
                rootLanguageId: +updatedRootCategory.rootLanguageId,
              }
            : pre
        )
      );
      showToast("Chỉnh sửa thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const handleDelete = async () => {
    const response = await updatePut(
      `/private/root-categories/${selectedItem.id}`,
      {
        ...selectedItem,
        status: false,
      }
    );
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setRootData((prevDatas) =>
        prevDatas.filter((pre) => pre.id !== selectedItem.id)
      );
      if (selectedItem.id === selectIdRoot?.id) {
        setSelectIdRoot(null);
        setSelectIdRootA(null);
        setSelectIdRootB(null);
        setSelectIdRootC(null);
      }
      showToast("Xóa thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }

    setShowDeleteModal(false);
  };

  const handleCreateA = async (rootCategory) => {
    const newRootCategory = {
      ...rootCategory,
      rootCategoryId: +rootCategory.rootCategoryId,
    };
    delete newRootCategory.rootCategory;

    const response = await create("private/master-categories", newRootCategory);
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      const newRootCategory = {
        stt: rootDataA.length + 1,
        id: response.id,
        ...rootCategory,
        rootCategory: selectIdRoot?.name,
        rootCategoryId: +rootCategory.rootCategoryId,
      };
      setRootDataA([...rootDataA, newRootCategory]);
      showToast("Thêm mới thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const handleUpdateA = async (updatedRootCategory) => {
    const newRootCategory = {
      ...updatedRootCategory,
      rootCategoryId: +updatedRootCategory.rootCategoryId,
    };
    delete newRootCategory.rootCategory;
    delete newRootCategory.stt;

    const response = await updatePut(
      `/private/master-categories/${updatedRootCategory.id}`,
      newRootCategory
    );

    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setRootDataA((prevDatas) =>
        prevDatas.map((pre) =>
          pre.id === updatedRootCategory.id
            ? {
                ...pre,
                ...updatedRootCategory,
                rootCategoryId: +updatedRootCategory.rootCategoryId,
              }
            : pre
        )
      );
      if (+updatedRootCategory.rootCategoryId !== selectIdRoot.id) {
        setRootDataA((prevDatas) =>
          prevDatas.filter((pre) => pre.rootCategoryId === selectIdRoot.id)
        );
      }
      showToast("Chỉnh sửa thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const handleDeleteA = async (id) => {
    const response = await updatePut(`/private/master-categories/${id}`, {
      ...selectedItem,
      status: false,
    });
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setRootDataA((prevDatas) => prevDatas.filter((pre) => pre.id !== id));
      if (selectedItem.id === selectIdRootA?.id) {
        setSelectIdRootA(null);
        setSelectIdRootB(null);
        setSelectIdRootC(null);
      }
      showToast("Xóa thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const handleChangeOrderA = async (listOrder) => {
    const response = await updatePut(
      `/private/master-categories/update-order`,
      listOrder
    );
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setRootDataA((prevUsers) => {
        return prevUsers
          .map((user) => {
            const update = listOrder.find((u) => u.id === user.id);
            return update ? { ...user, order: update.order } : user;
          })
          .sort((x, y) => x.order - y.order);
      });
      showToast("Chỉnh sửa thứ tự thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const handleCreateB = async (rootCategory) => {
    const newRootCategory = {
      ...rootCategory,
      masterCategoryId: +rootCategory.masterCategoryId,
    };
    delete newRootCategory.masterCategory;

    const response = await create("/private/categories", newRootCategory);
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      const newRootCategory = {
        stt: rootDataB.length + 1,
        id: response.id,
        ...rootCategory,
        masterCategoryId: +rootCategory.masterCategoryId,
        masterCategory: selectIdRootA?.name,
      };
      setRootDataB([...rootDataB, newRootCategory]);
      if (+rootCategory.masterCategoryId === selectIdRootA.id) {
        setRootDataB([...rootDataB, newRootCategory]);
      }
      showToast("Thêm mới thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const handleUpdateB = async (updatedRootCategory) => {
    const newRootCategory = {
      ...updatedRootCategory,
      masterCategoryId: +updatedRootCategory.masterCategoryId,
    };
    delete newRootCategory.masterCategory;
    delete newRootCategory.stt;

    const response = await updatePut(
      `/private/categories/${updatedRootCategory.id}`,
      newRootCategory
    );

    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setRootDataB((prevDatas) =>
        prevDatas.map((pre) =>
          pre.id === updatedRootCategory.id
            ? {
                ...pre,
                ...updatedRootCategory,
                masterCategoryId: +updatedRootCategory.masterCategoryId,
              }
            : pre
        )
      );
      if (+updatedRootCategory.masterCategoryId !== selectIdRootA?.id) {
        setRootDataB((prevDatas) =>
          prevDatas.filter((pre) => pre.masterCategoryId === selectIdRootA?.id)
        );
      }
      showToast("Chỉnh sửa thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const handleDeleteB = async (id) => {
    const response = await updatePut(`/private/categories/${id}`, {
      ...selectedItem,
      status: false,
    });
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setRootDataB((prevDatas) => prevDatas.filter((pre) => pre.id !== id));
      if (id === setSelectIdRootB?.id) {
        setSelectIdRootB(null);
        setSelectIdRootC(null);
      }
      showToast("Xóa thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }

    setShowDeleteModal(false);
  };

  const handleChangeOrderB = async (listOrder) => {
    const response = await updatePut(`/private/categories-order`, listOrder);
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setRootDataB((prevUsers) => {
        return prevUsers
          .map((user) => {
            const update = listOrder.find((u) => u.id === user.id);
            return update ? { ...user, order: update.order } : user;
          })
          .sort((x, y) => x.order - y.order);
      });
      showToast("Chỉnh sửa thứ tự thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const handleCreateC = async (rootCategory) => {
    let lastOrder = 1;
    if (rootDataC.length > 0) {
      lastOrder = (rootDataC[rootDataC.length - 1]?.order || 0) + 1;
    }
    const newRootCategory = {
      ...rootCategory,
      categoryId: +rootCategory.categoryId,
      order: lastOrder,
    };
    delete newRootCategory.category;

    const response = await create("/private/parts", newRootCategory);
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      const newRootCategory = {
        stt: rootDataC.length + 1,
        id: response.id,
        ...rootCategory,
        categoryId: +rootCategory.categoryId,
        category: selectIdRootB?.name,
        order: lastOrder,
      };
      if (+rootCategory.categoryId === selectIdRootB.id) {
        setRootDataC([...rootDataC, newRootCategory]);
      }
      showToast("Thêm mới thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const handleUpdateC = async (updatedRootCategory) => {
    const newRootCategory = {
      ...updatedRootCategory,
      categoryId: +updatedRootCategory.categoryId,
    };
    delete newRootCategory.category;
    delete newRootCategory.stt;

    const response = await updatePut(
      `/private/parts/${updatedRootCategory.id}`,
      newRootCategory
    );

    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setRootDataC((prevDatas) =>
        prevDatas.map((pre) =>
          pre.id === updatedRootCategory.id
            ? {
                ...pre,
                ...updatedRootCategory,
                categoryId: +updatedRootCategory.categoryId,
              }
            : pre
        )
      );
      if (+updatedRootCategory.categoryId !== selectIdRootB?.id) {
        setRootDataC((prevDatas) =>
          prevDatas.filter((pre) => pre.categoryId === selectIdRootB?.id)
        );
      }
      showToast("Chỉnh sửa thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const handleDeleteC = async (id) => {
    const response = await updatePut(`/private/parts/${id}`, {
      ...selectedItem,
      status: false,
    });
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setRootDataC((prevDatas) => prevDatas.filter((pre) => pre.id !== id));
      if (id === setSelectIdRootC?.id) {
        setSelectIdRootC(null);
      }
      showToast("Xóa thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }

    setShowDeleteModal(false);
  };

  const handleChangeOrderC = async (listOrder) => {
    const response = await updatePut(`/private/parts`, listOrder);
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setRootDataC((prevUsers) => {
        return prevUsers
          .map((user) => {
            const update = listOrder.find((u) => u.id === user.id);
            return update ? { ...user, order: update.order } : user;
          })
          .sort((x, y) => x.order - y.order);
      });
      showToast("Chỉnh sửa thứ tự thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const handleCreateD = async (rootCategory) => {
    let lastOrder = 1;
    if (rootDataD.length > 0) {
      lastOrder = (rootDataD[rootDataD.length - 1]?.order || 0) + 1;
    }
    const newRootCategory = {
      ...rootCategory,
      partCategoryId: +rootCategory.partCategoryId,
      order: lastOrder,
    };
    delete newRootCategory.category;

    const response = await create("/private/course", {
      ...newRootCategory,
      masterCategoryId: selectIdRootUpdate
        ? selectIdRootUpdate?.id
        : selectIdRootA.id,
    });
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      const newRootCategory = {
        stt: rootDataU.length + 1,
        id: response.id,
        ...rootCategory,
        partCategoryId: +rootCategory.partCategoryId,
        partCategory: selectIdRootC?.name,
        order: lastOrder,
      };
      if (selectIdRootC && +rootCategory.partCategoryId === selectIdRootC.id) {
        setRootDataD([...rootDataD, newRootCategory]);
      }
      if (
        selectIdRootUpdate &&
        +rootCategory.masterCategoryId === selectIdRootUpdate.id
      ) {
        setRootDataU([
          ...rootDataU,
          {
            ...newRootCategory,
            masterCategory: selectIdRootUpdate?.name,
          },
        ]);
      }
      showToast("Thêm mới thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const handleUpdateD = async (updatedRootCategory) => {
    let newRootCategory = {
      ...updatedRootCategory,
      teacherId: +updatedRootCategory,
    };
    if (!selectIdRoot?.isUpdateCategory) {
      newRootCategory = {
        ...updatedRootCategory,
        partCategoryId: +updatedRootCategory.partCategoryId,
      };
    }
    delete newRootCategory.category;
    delete newRootCategory.masterCategory;
    delete newRootCategory.stt;

    const response = await updatePut(
      `/private/course/${updatedRootCategory.id}`,
      newRootCategory
    );

    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setRootDataD((prevDatas) =>
        prevDatas.map((pre) =>
          pre.id === updatedRootCategory.id
            ? {
                ...pre,
                ...newRootCategory,
              }
            : pre
        )
      );
      if (
        selectIdRootC &&
        +updatedRootCategory.partCategoryId !== selectIdRootC?.id
      ) {
        setRootDataD((prevDatas) =>
          prevDatas.filter((pre) => pre.partCategoryId === selectIdRootC.id)
        );
      }
      if (
        selectIdRootUpdate &&
        +updatedRootCategory.masterCategoryId !== selectIdRootUpdate.id
      ) {
        setRootDataU((prevDatas) =>
          prevDatas.filter(
            (pre) => pre.masterCategoryId === selectIdRootUpdate.id
          )
        );
      }
      showToast("Chỉnh sửa thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const handleDeleteD = async (id) => {
    const response = await updatePut(`/private/course/${id}`, {
      ...selectedItem,
      status: false,
    });
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      if (selectIdRoot?.isUpdateCategory) {
        setRootDataU((prevDatas) => prevDatas.filter((pre) => pre.id !== id));
      } else {
        setRootDataD((prevDatas) => prevDatas.filter((pre) => pre.id !== id));
      }
      showToast("Xóa thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }

    setShowDeleteModal(false);
  };

  const handleChangeOrderD = async (listOrder) => {
    const response = await updatePut(`/private/course`, listOrder);
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setRootDataD((prevUsers) => {
        return prevUsers
          .map((user) => {
            const update = listOrder.find((u) => u.id === user.id);
            return update ? { ...user, order: update.order } : user;
          })
          .sort((x, y) => x.order - y.order);
      });
      showToast("Chỉnh sửa thứ tự thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const columns = [
    {
      accessorKey: "stt",
      header: "STT",
      enableSorting: true,
      isFilter: false,
    },
    {
      accessorKey: "name",
      header: "Tên danh mục gốc",
      enableSorting: true,
      isFilter: true,
    },
    {
      accessorKey: "description",
      header: "Mô tả",
      enableSorting: true,
      isFilter: true,
    },
    {
      accessorKey: "rootLanguageId",
      header: "Ngôn Ngữ",
      enableSorting: true,
      isFilter: true,
      type: "select",
      options: language,
      cell: ({ row }) => {
        const isSelected = language.find(
          (x) => x.id === row.original.rootLanguageId
        )?.name;
        return (
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle variant="success" id={`dropdown-${row.id}`}>
              {isSelected || "Select"}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {language.map((option) => (
                <Dropdown.Item
                  key={option.id}
                  disabled={isSelected} // Disable if already selected
                >
                  {option.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
    {
      header: "Hành động",
      isFilter: false,
      cell: ({ row }) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            variant="warning"
            size="sm"
            onClick={() => handleShowEditModal(row.original)}
          >
            <FaEdit />
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => handleShowDeleteModal(row.original)}
          >
            <FaTrash />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: "10px 0" }}>
      <h4>Quản lý các khóa học</h4>
      <p>
        Chú ý: Các danh mục được sắp xếp từ trên xuống. Hãy thực hiện filter
        theo danh mục cha để thao tác với danh mục con. Ví dụ chọn JLPT sẽ thấy
        các khóa học của JLPT ở mục 2. để thao tác
      </p>
      <div style={{ marginTop: "10px" }}>
        <Accordion activeKey={openItems} onSelect={handleToggle}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="wrap-content">
                <div className="title-category">
                  1. Danh mục gốc (JLPT, Kaiwa,...)
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="d-flex" style={{ justifyContent: "flex-end" }}>
                <Button
                  variant="primary"
                  style={{ marginBottom: "8px" }}
                  onClick={handleShowCreateModal}
                >
                  Thêm mới
                </Button>
              </div>
              <DataTable1 data={rootData} columns={columns} />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="wrap-content">
                <div className="title-category">
                  2. Danh mục khóa học của danh mục gốc (N1, N2, N3, N4, N5,...)
                </div>
                <div>
                  <Dropdown as={ButtonGroup} onClick={handleDropdownToggle}>
                    <Dropdown.Toggle
                      variant="success"
                      id={`dropdown-root-category`}
                    >
                      {selectIdRoot?.name || "Chọn danh mục"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {rootData.map((option) => (
                        <Dropdown.Item
                          key={option.id}
                          onClick={() =>
                            setSelectIdRoot({
                              id: option.id,
                              name: option.name,
                              isUpdateCategory: option.isUpdateCategory,
                            })
                          }
                        >
                          {option.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {selectIdRoot ? (
                <CourseCategory
                  masterCategories={rootDataA}
                  handleChangeOrder={handleChangeOrderA}
                  parents={rootData}
                  handleUpdateA={handleUpdateA}
                  handleCreateA={handleCreateA}
                  handleDeleteA={handleDeleteA}
                  rootCategoryId={selectIdRoot?.id}
                />
              ) : (
                <div
                  style={{
                    color: "red",
                    fontSize: 18,
                    fontWeight: 500,
                    marginTop: "10px",
                  }}
                >
                  Chọn danh mục để thao tác!
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>

          {!selectIdRoot?.isUpdateCategory ? (
            <>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <div className="wrap-content">
                    <div className="title-category">
                      3. Danh mục khóa học của danh mục 2 (Ví dụ khóa học Level
                      1 của N1)
                    </div>
                    <div>
                      <Dropdown as={ButtonGroup} onClick={handleDropdownToggle}>
                        <Dropdown.Toggle
                          variant="success"
                          id={`dropdown-root-category-1`}
                        >
                          {selectIdRootA?.name || "Chọn danh mục"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {rootDataA.map((option) => (
                            <Dropdown.Item
                              key={option.id}
                              onClick={() =>
                                setSelectIdRootA({
                                  id: option.id,
                                  name: option.name,
                                })
                              }
                            >
                              {option.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {selectIdRootA ? (
                    <CategoryLV3
                      handleChangeOrder={handleChangeOrderB}
                      masterCategories={rootDataB}
                      parents={rootDataA}
                      handleUpdateA={handleUpdateB}
                      handleCreateA={handleCreateB}
                      handleDeleteA={handleDeleteB}
                      masterCategoryId={selectIdRootA?.id}
                    />
                  ) : (
                    <div
                      style={{
                        color: "red",
                        fontSize: 18,
                        fontWeight: 500,
                        marginTop: "10px",
                      }}
                    >
                      Chọn danh mục để thao tác!
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div className="wrap-content">
                    <div className="title-category">
                      4. Các học phần chia theo mục 3
                    </div>
                    <div>
                      <Dropdown as={ButtonGroup} onClick={handleDropdownToggle}>
                        <Dropdown.Toggle
                          variant="success"
                          id={`dropdown-root-category-2`}
                        >
                          {selectIdRootB?.name || "Chọn danh mục"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {rootDataB.map((option) => (
                            <Dropdown.Item
                              key={option.id}
                              onClick={() =>
                                setSelectIdRootB({
                                  id: option.id,
                                  name: option.name,
                                })
                              }
                            >
                              {option.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {selectIdRootB ? (
                    <CategoryLV4
                      masterCategories={rootDataC}
                      handleChangeOrder={handleChangeOrderC}
                      parents={rootDataB}
                      handleUpdateA={handleUpdateC}
                      handleCreateA={handleCreateC}
                      handleDeleteA={handleDeleteC}
                      masterCategoryId={selectIdRootB?.id}
                    />
                  ) : (
                    <div
                      style={{
                        color: "red",
                        fontSize: 18,
                        fontWeight: 500,
                        marginTop: "10px",
                      }}
                    >
                      Chọn danh mục để thao tác!
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <div className="wrap-content">
                    <div className="title-category">
                      5. Các bài học trong từng phần
                    </div>
                    <div>
                      <Dropdown as={ButtonGroup} onClick={handleDropdownToggle}>
                        <Dropdown.Toggle
                          variant="success"
                          id={`dropdown-root-category-1`}
                        >
                          {selectIdRootC?.name || "Chọn danh mục"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {rootDataC.map((option) => (
                            <Dropdown.Item
                              key={option.id}
                              onClick={() =>
                                setSelectIdRootC({
                                  id: option.id,
                                  name: option.name,
                                })
                              }
                            >
                              {option.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  {selectIdRootC ? (
                    <CategoryLV5
                      masterCategories={rootDataD}
                      parents={rootDataC}
                      handleChangeOrder={handleChangeOrderD}
                      handleUpdateA={handleUpdateD}
                      handleCreateA={handleCreateD}
                      handleDeleteA={handleDeleteD}
                      masterCategoryId={selectIdRootC?.id}
                      teachers={teachers}
                    />
                  ) : (
                    <div
                      style={{
                        color: "red",
                        fontSize: 18,
                        fontWeight: 500,
                        marginTop: "10px",
                      }}
                    >
                      Chọn danh mục để thao tác!
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </>
          ) : (
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <div className="wrap-content">
                  <div className="title-category">3. Các bài học cực hay</div>
                  <div>
                    <Dropdown as={ButtonGroup} onClick={handleDropdownToggle}>
                      <Dropdown.Toggle
                        variant="success"
                        id={`dropdown-root-category-1`}
                      >
                        {selectIdRootUpdate?.name || "Chọn danh mục"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {rootDataA.map((option) => (
                          <Dropdown.Item
                            key={option.id}
                            onClick={() =>
                              setSelectIdRootUpdate({
                                id: option.id,
                                name: option.name,
                              })
                            }
                          >
                            {option.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {selectIdRootUpdate ? (
                  <CategoryLV6
                    masterCategories={rootDataU}
                    parents={rootDataA}
                    handleUpdateA={handleUpdateD}
                    handleCreateA={handleCreateD}
                    handleDeleteA={handleDeleteD}
                    masterCategoryId={selectIdRootUpdate?.id}
                  />
                ) : (
                  <div
                    style={{
                      color: "red",
                      fontSize: 18,
                      fontWeight: 500,
                      marginTop: "10px",
                    }}
                  >
                    Chọn danh mục để thao tác!
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          )}
        </Accordion>
      </div>

      <CreateRootCategoryModal
        show={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        handleCreate={handleCreate}
        languages={language}
      />
      <EditRootCategoryModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        rootCategory={selectedItem}
        handleUpdate={handleUpdate}
        languages={language || []}
      />
      <DeleteConfirmModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default HomeAdmin;
