import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { fetchList } from "../services";
import { TailSpin } from "react-loader-spinner";
import { useWindowWidth } from "../context/WindowWidthContext";
import { useGlobalState } from "../context/GlobalContext";
import { FaCircle } from "react-icons/fa";
import { BsTriangleFill } from "react-icons/bs";
import { DOMAIN } from "../constants";
import { FaTimes } from "react-icons/fa";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "../context/ToastContext";
import httpService from "../utils/request";
import { getUserId } from "../utils";
import "./payment.css";

const languageSchema = z.object({
  image: z.instanceof(FileList).refine((fileList) => fileList.length > 0, {
    message: "Ảnh chuyển khoản là bắt buộc",
  }),
});

const styles = {
  contentMasterCategory: (isMobile) => ({
    backgroundColor: "#fff",
    borderRadius: "20px",
    padding: !isMobile ? "10px 30px" : "10px",
    margin: !isMobile ? "10px 20px 0 20px" : "10px 0 0 0",
    flex: 1,
  }),
  contentMasterCategoryLoading: (isMobile) => ({
    backgroundColor: "#fff",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    padding: !isMobile ? "10px 30px" : "10px",
    margin: !isMobile ? "10px 20px 0 20px" : "10px 0 0 0",
    flex: 1,
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  contentContact: (isMobile) => ({
    margin: !isMobile ? "5px 20px 0 20px" : "5px 0px 0 0px",
    backgroundColor: "#fff",
    borderRadius: "20px",
    padding: "10px 30px",
    display: "flex",
    alignItems: "center",
  }),
  contentDescription: {
    color: "red",
    display: "flex",
    alignItems: "center",
  },
  contentDescription1: {
    display: "flex",
  },
  informationBank: {
    display: "flex",
    backgroundImage: 'url("/public/images/background.jpg")',
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
};

const closeIconStyles = {
  position: "absolute",
  top: "-10px",
  right: "-5px",
  width: "30px",
  height: "30px",
  backgroundColor: "#fff",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "18px",
  cursor: "pointer",
  color: "#333",
  border: "1px solid #ccc",
  zIndex: "1050",
  transition: "background-color 0.3s ease, color 0.3s ease",
};

const Payment = () => {
  const [showPopup, setShowPopup] = useState(false);
  const { isMobile } = useWindowWidth();
  const { state } = useGlobalState();
  const support = state.supportData;
  const [allVNBanks, setAllVNBanks] = useState([]);
  const [allNBBanks, setAllNBBanks] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      title: "",
      status: true,
      isActive: true,
    },
    resolver: zodResolver(languageSchema),
  });
  const showToast = useToast();

  const [imagePreview, setImagePreview] = useState(null);
  const imageFile = watch("image");

  useEffect(() => {
    if (imageFile && imageFile.length > 0) {
      const file = imageFile[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setImagePreview(null);
    }
  }, [imageFile]);

  const onSubmit = async (data) => {
    try {
      try {
        const dataImage = await httpService.post("/private/upload", {
          image: imagePreview,
        });
        const dataResquest = {
          ...data,
          image: dataImage,
          userId: getUserId(),
          paymentStatus: "REQUESTED",
        };
        const response = await httpService.post(
          "/private/payments",
          dataResquest
        );
        setShowPopup(false);
        showToast("Gửi thông tin thanh toán thành công", { type: "success" });
        reset();
        setImagePreview(null);
      } catch (error) {
        showToast(error.response.data, {
          position: "top-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      showToast("Có lỗi xảy ra. Vui lòng thử lại.", { type: "error" });
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const rootDatas = await fetchList("/public/bankActives");
      if (rootDatas && rootDatas.length > 0) {
        setAllVNBanks(rootDatas.filter((x) => x.type === "BANK_VN"));
        setAllNBBanks(rootDatas.filter((x) => x.type === "BANK_JP"));
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const handleZaloClick = () => {
    window.open(support.zaloUrl, "_blank");
  };

  const handleFbClick = () => {
    window.open(support.zaloUrl, "_blank");
  };

  const handlePaymentClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <Container
      className="main-content"
      style={{
        paddingBottom: "10px",
        flexGrow: 1,
        minHeight: "calc(100vh - 60px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {loading ? (
        <div style={styles.contentMasterCategoryLoading(isMobile)}>
          <TailSpin color="green" radius={"8px"} />
        </div>
      ) : (
        <div style={styles.contentMasterCategory(isMobile)}>
          <div style={{ padding: "20px 0 30px 0" }}>
            <div
              style={{
                padding: "20px",
                marginBottom: "20px",
                borderRadius: "10px",
                backgroundColor: "#58d062",
                boxShadow:
                  " 2px 2px 5px rgba(0, 0, 0, 0.3), -2px -2px 5px rgba(0, 0, 0, 0.2)",
              }}
            >
              <div
                className="d-flex"
                style={{
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ width: "100%", textAlign: "center" }}>
                  <div
                    style={{
                      fontSize: isMobile ? "20px" : "26px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    Hướng Dẫn Thanh Toán Khóa Học Tại
                  </div>
                  <div style={{ fontSize: "18px", fontWeight: "600" }}>
                    Khotiengnhat.com
                  </div>
                </div>
              </div>
            </div>
            <div style={{ margin: "15px 0" }}>
              <div style={styles.contentDescription}>
                <div style={{ marginTop: "-3px" }}>
                  <FaCircle style={{ color: "#d73061" }} />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    marginLeft: "5px",
                  }}
                >
                  Thông Báo Quan Trọng:
                </div>
              </div>

              <div style={styles.contentDescription1}>
                <div style={{ marginTop: "-3px", position: "relative" }}>
                  <BsTriangleFill style={{ color: "#cd9d56" }} />
                  <span
                    style={{
                      position: "absolute",
                      top: "4px",
                      fontSize: "13px",
                      left: "7px",
                    }}
                  >
                    !
                  </span>
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    marginLeft: "5px",
                  }}
                >
                  Từ ngày 08/11/2024, Hội phí trọn đời tại website trở về giá
                  gốc <strong>1.500.000đ</strong>. Mức phí này sẽ áp dụng cho
                  tất cả các các thành viên mới.
                </div>
              </div>

              <div style={styles.contentDescription1}>
                <div style={{ marginTop: "-3px", position: "relative" }}>
                  <BsTriangleFill style={{ color: "#cd9d56" }} />
                  <span
                    style={{
                      position: "absolute",
                      top: "4px",
                      fontSize: "13px",
                      left: "7px",
                    }}
                  >
                    !
                  </span>
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    marginLeft: "5px",
                  }}
                >
                  Trước ngày trên, vẫn áp dụng mức <strong>599.000đ</strong>{" "}
                  trên 1 khóa học trọn đời.
                </div>
              </div>
            </div>

            {allVNBanks.map((item, index) => {
              let indexData = (index + 1).toString();
              if (indexData.length === 1) {
                indexData = `0${indexData}`;
              }
              return (
                <div
                  style={{ margin: "15px 0" }}
                  className="wrap-information-bank"
                >
                  <div
                    className="information-bank"
                    style={{ flexDirection: isMobile ? "column" : "row" }}
                  >
                    <div
                      style={{
                        fontSize: !isMobile ? "40px" : "38px",
                        color: "green",
                        textDecoration: "underline",
                        fontWeight: "600",
                      }}
                    >
                      {indexData}
                    </div>
                    <div
                      style={{
                        flex: "1",
                        paddingLeft: !isMobile ? "20px" : "0px",
                        paddingTop: !isMobile ? "0px" : "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            color: "red",
                            fontSize: !isMobile ? "20px" : "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Chuyển khoản tới {item.bankName}
                        </div>
                        <div
                          style={{
                            color: "red",
                            fontSize: !isMobile ? "20px" : "16px",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          {item.bankNumber} - {item.accountName}
                        </div>
                      </div>
                      <div style={{ padding: isMobile ? "10px 0" : "0" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#000",
                            fontWeight: "600",
                            fontSize: !isMobile ? "18px" : "16px",
                          }}
                        >
                          <div
                            style={{
                              minWidth: "8px",
                              width: "8px",
                              height: "8px",
                              backgroundColor: "green",
                            }}
                          ></div>
                          <div style={{ marginLeft: "10px" }}>
                            <label style={{ width: "90px" }}>Nội dung</label>:
                            Họ và Tên - Số điện thoại
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          <div
                            style={{
                              minWidth: "8px",
                              width: "8px",
                              height: "8px",
                              backgroundColor: "green",
                            }}
                          ></div>
                          <div style={{ marginLeft: "10px" }}>
                            <label style={{ width: "90px" }}>Số tiền</label>
                            {": "}
                            599,000đ.
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          <div
                            style={{
                              minWidth: "8px",
                              width: "8px",
                              height: "8px",
                              backgroundColor: "green",
                            }}
                          ></div>
                          <div style={{ marginLeft: "10px" }}>
                            Học full các khóa học tại website.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: !isMobile ? "200px" : "180px",
                        padding: "5px",
                        border: "1px solid green",
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "auto",
                          objectFit: "cover",
                        }}
                        src={`${DOMAIN}${item.image}`}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              );
            })}

            <div
              style={{
                margin: "15px 0",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <div style={{ width: "80%", color: "#000", fontWeight: "600" }}>
                Lưu ý: Sau khi chuyển khoản, vui lòng chụp bill chuyển khoản,
                gửi tới đường link bên cạnh để admin phê duyệt.
              </div>
              <div
                style={{
                  width: isMobile ? "100%" : "20%",
                  marginTop: isMobile ? "10px" : 0,
                }}
              >
                <Button
                  onClick={handlePaymentClick}
                  variant="success"
                  className="login-button"
                  style={{ borderRadius: "20px" }}
                >
                  Xác Nhận Thanh Toán
                </Button>
                <Button
                  onClick={handleFbClick}
                  variant="success"
                  className="login-button"
                  style={{ marginTop: "5px", borderRadius: "20px" }}
                >
                  Nhắn Tin Admin
                </Button>
                <Button
                  onClick={handleZaloClick}
                  variant="success"
                  className="login-button"
                  style={{ marginTop: "5px", borderRadius: "20px" }}
                >
                  Zalo Hỗ Trợ
                </Button>
              </div>
            </div>

            {allNBBanks.map((item, index) => {
              const countVNBanks = allVNBanks.length;
              let indexData = (index + 1 + countVNBanks).toString();
              if (indexData.length === 1) {
                indexData = `0${indexData}`;
              }
              return (
                <div
                  style={{ margin: "15px 0" }}
                  className="wrap-information-bank"
                >
                  <div
                    className="information-bank"
                    style={{ flexDirection: isMobile ? "column" : "row" }}
                  >
                    <div
                      style={{
                        fontSize: !isMobile ? "40px" : "38px",
                        color: "green",
                        textDecoration: "underline",
                        fontWeight: "600",
                      }}
                    >
                      {indexData}
                    </div>
                    <div
                      style={{
                        flex: "1",
                        paddingLeft: !isMobile ? "20px" : "0px",
                        paddingTop: !isMobile ? "0px" : "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            color: "red",
                            fontSize: !isMobile ? "20px" : "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Chuyển khoản Nhật Bản
                        </div>
                        <div
                          style={{
                            color: "#000",
                            fontSize: !isMobile ? "18px" : "16px",
                            fontWeight: "bold",
                          }}
                        >
                          {item.accountName}
                        </div>

                        <div
                          style={{
                            color: "#000",
                            fontSize: !isMobile ? "18px" : "16px",
                            fontWeight: "bold",
                          }}
                        >
                          {item.bankNumber}
                        </div>

                        <div
                          style={{
                            color: "#000",
                            fontSize: !isMobile ? "18px" : "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Ngân Hàng {item.bankName}
                        </div>
                      </div>
                      <div style={{ padding: isMobile ? "10px 0" : "0" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#000",
                            fontWeight: "600",
                            fontSize: "18px",
                          }}
                        >
                          <div
                            style={{
                              width: "8px",
                              minWidth: "8px",
                              height: "8px",
                              backgroundColor: "green",
                            }}
                          ></div>
                          <div
                            style={{
                              marginLeft: "10px",
                              fontSize: !isMobile ? "18px" : "16px",
                            }}
                          >
                            <label style={{ width: "90px" }}>Nội dung</label>:
                            Họ và Tên - Số điện thoại
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#000",
                            fontWeight: "600",
                            fontSize: !isMobile ? "18px" : "16px",
                          }}
                        >
                          <div
                            style={{
                              width: "8px",
                              minWidth: "8px",
                              height: "8px",
                              backgroundColor: "green",
                            }}
                          ></div>
                          <div style={{ marginLeft: "10px" }}>
                            <label style={{ width: "90px" }}>Số tiền</label>
                            {": "}
                            599,000đ.
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            color: "#000",
                            fontWeight: "600",
                          }}
                        >
                          <div
                            style={{
                              width: "8px",
                              minWidth: "8px",
                              height: "8px",
                              backgroundColor: "green",
                            }}
                          ></div>
                          <div style={{ marginLeft: "10px" }}>
                            Học full các khóa học tại website.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        maxWidth: !isMobile ? "400px" : "100%",
                        height: "auto",
                        padding: "5px",
                        border: "1px solid green",
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                          height: "auto",
                          objectFit: "cover",
                        }}
                        src={`${DOMAIN}${item.image}`}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              );
            })}

            <div
              style={{
                margin: "15px 0",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <div style={{ width: "80%", color: "#000", fontWeight: "600" }}>
                Lưu ý: Sau khi chuyển khoản, vui lòng chụp bill chuyển khoản,
                gửi tới đường link bên cạnh để admin phê duyệt.
              </div>
              <div
                style={{
                  width: isMobile ? "100%" : "20%",
                  marginTop: isMobile ? "10px" : 0,
                }}
              >
                <Button
                  onClick={handlePaymentClick}
                  variant="success"
                  className="login-button"
                  style={{ borderRadius: "20px" }}
                >
                  Xác Nhận Thanh Toán
                </Button>
                <Button
                  onClick={handleFbClick}
                  variant="success"
                  className="login-button"
                  style={{ marginTop: "5px", borderRadius: "20px" }}
                >
                  Nhắn Tin Admin
                </Button>
                <Button
                  onClick={handleZaloClick}
                  variant="success"
                  className="login-button"
                  style={{ marginTop: "5px", borderRadius: "20px" }}
                >
                  Zalo Hỗ Trợ
                </Button>
              </div>
            </div>

            <div style={{ fontWeight: "bold" }}>Liên hệ với admin:</div>
            <div style={{ fontWeight: "600" }}>
              Bạn có thể chủ động nhắn tin qua Facebook Messenger tại:
              Khotiengnhat.com/chat
            </div>
            <div style={{ fontWeight: "600" }}>
              Hoặc bạn cũng có thể liên hệ trực tiếp đến Admin qua Số điện
              thoại: 0989.584.777
            </div>
          </div>
        </div>
      )}
      {showPopup && (
        <Modal
          style={{
            borderRadius: "10px",
          }}
          show={showPopup}
          onHide={handleClosePopup}
          centered
        >
          <Modal.Body
            style={{
              position: "relative",
              padding: 0,
              borderRadius: "5px",
            }}
          >
            <span style={closeIconStyles} onClick={handleClosePopup}>
              <FaTimes />
            </span>
            <Form
              className="wrap-user-bank-info"
              style={{
                padding: "20px",
                borderRadius: "5px",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Form.Group controlId="courseCategoryImage">
                <Form.Label style={{ fontWeight: "bold" }}>
                  Hình ảnh chuyển khoản <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  isInvalid={!!errors.image}
                  type="file"
                  accept="image/*"
                  {...register("image", { required: "File is required" })}
                />
                {imagePreview && (
                  <div className="mt-3">
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{
                        width: "160px",
                        height: "auto",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
                {errors.image && (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ fontWeight: "bold" }}
                  >
                    {errors.image.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Modal.Footer>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontWeight: "600",
                    borderTop: "none",
                  }}
                >
                  <Button
                    className="custom-btn"
                    style={{
                      fontSize: "18px",
                      background: "linear-gradient(to right, #ff7f00, #ff9800)",
                      width: !isMobile ? "80%" : "100%",
                      color: "#000",
                      padding: "10px",
                      borderRadius: "50px",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      border: "unset",
                      transition: "background 0.3s ease",
                    }}
                    type="submit"
                  >
                    Gửi Thông Tin Ngay
                  </Button>
                </div>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </Container>
  );
};

export default Payment;
