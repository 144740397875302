import React, { useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { languageService } from "../../../../services/language";
import { useToast } from "../../../../context/ToastContext";

const languageSchema = z.object({
  name: z.string().min(1, "Tên ngôn ngữ là bắt buộc"),
  code: z.string().min(1, "Mã ngôn ngữ là bắt buộc"),
  description: z.string().optional(),
  status: z.boolean(),
});

const EditLanguageModal = ({ show, handleClose, language, handleUpdate }) => {
  const showToast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: language,
    resolver: zodResolver(languageSchema),
  });

  useEffect(() => {
    if (language) {
      reset(language);
    }
  }, [language, reset]);

  const status = watch("status");

  const onSubmit = async (data) => {
    try {
      await languageService.editLanguage(language.id, data);
      handleUpdate({ id: language.id, ...data });
      showToast("Chỉnh sửa ngôn ngữ thành công", {
        type: "success",
      });
      reset();
    } catch (error) {
      showToast("Có lỗi xảy ra! Chỉnh sửa không thành công", {
        type: "error",
      });
    }
  };

  const handleCloseModal = () => {
    reset();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>Chỉnh sửa ngôn ngữ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="languageName">
            <Form.Label>
              Tên ngôn ngữ <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              {...register("name")}
              isInvalid={!!errors.name}
            />
            {errors.name && (
              <Form.Control.Feedback type="invalid">
                {errors.name.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="languageCode" className="mt-1">
            <Form.Label>
              Mã ngôn ngữ <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              disabled
              type="text"
              {...register("code")}
              isInvalid={!!errors.code}
            />
            {errors.code && (
              <Form.Control.Feedback type="invalid">
                {errors.code.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="languageDescription" className="mt-1">
            <Form.Label>Mô tả</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              {...register("description")}
              isInvalid={!!errors.description}
            />
            {errors.description && (
              <Form.Control.Feedback type="invalid">
                {errors.description.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          {/* <Form.Group controlId="languageStatus" className="mt-2 d-flex gap-2">
            <Form.Label>Trạng thái active</Form.Label>
            <Form.Check
              type="switch"
              {...register("status")}
              checked={!!status}
              onChange={(e) => setValue("status", e.target.checked)}
            />
          </Form.Group> */}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Đóng
            </Button>
            <Button variant="primary" type="submit">
              Cập nhật
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditLanguageModal;
