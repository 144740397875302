import React, { useState } from "react";
import DataTable from "../../components/admin/DataTable";
import { Button } from "react-bootstrap";
import CreateCourseCategoryModal from "../../components/admin/modals/course-category-update/CreateCategory";
import EditCourseCategoryModal from "../../components/admin/modals/course-category-update/EditCategory";
import { FaEdit, FaTrash } from "react-icons/fa";
import DeleteConfirmModal from "../../components/admin/modals/course-category-update/DeleteCategory";

const CourseCategory = ({
  masterCategories,
  parents,
  handleUpdateA,
  handleCreateA,
  handleDeleteA,
  masterCategoryId,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleShowCreateModal = () => setShowCreateModal(true);
  const handleShowEditModal = (item) => {
    setShowEditModal(true);
    setSelectedItem(item);
  };
  const handleShowDeleteModal = (item) => {
    setShowDeleteModal(true);
    setSelectedItem(item);
  };

  const handleCreate = (rootCategory) => {
    handleCreateA(rootCategory);
  };

  const handleUpdate = (updatedRootCategory) => {
    handleUpdateA(updatedRootCategory);
  };

  const handleDelete = () => {
    handleDeleteA(selectedItem.id);
    setShowDeleteModal(false);
  };

  const columns = [
    {
      accessorKey: "stt",
      header: "STT",
      enableSorting: true,
      isFilter: false,
    },
    {
      accessorKey: "name",
      header: "Bài học",
      enableSorting: true,
      isFilter: true,
    },
    {
      accessorKey: "description",
      header: "Mô tả",
      enableSorting: true,
      isFilter: true,
    },
    {
      accessorKey: "url",
      header: "Link",
      isFilter: true,
    },
    {
      accessorKey: "masterCategory",
      header: "Danh mục gốc",
    },
    {
      header: "Hành động",
      cell: ({ row }) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            variant="warning"
            size="sm"
            onClick={() => handleShowEditModal(row.original)}
          >
            <FaEdit />
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => handleShowDeleteModal(row.original)}
          >
            <FaTrash />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Button
        variant="primary"
        style={{ marginBottom: "8px" }}
        onClick={handleShowCreateModal}
      >
        Thêm mới
      </Button>
      <DataTable data={masterCategories} columns={columns} />
      <CreateCourseCategoryModal
        show={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        handleCreate={handleCreate}
        parents={parents}
        categoryId={masterCategoryId}
      />
      <EditCourseCategoryModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        rootCategory={selectedItem}
        handleUpdate={handleUpdate}
        parents={parents}
        categoryId={masterCategoryId}
      />
      <DeleteConfirmModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default CourseCategory;
