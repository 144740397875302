import React, { useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import httpService from "../../../../utils/request";
import { getImage } from "../../../../utils";
import { useToast } from "../../../../context/ToastContext";

const languageSchema = z.object({
  paymentStatus: z.string().min(1, "Trạng thái là bắt buộc"),
});

const EditLanguageModal = ({ show, handleClose, language, handleUpdate }) => {
  const showToast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: language,
    resolver: zodResolver(languageSchema),
  });

  const onSubmit = async (data) => {
    try {
      await httpService.put(`/private/payments/${language.id}`, {
        paymentStatus: data.paymentStatus,
        userId: language.userId,
      });
      handleUpdate({ id: language.id, ...data });
      showToast("Cập nhật thanh toán cho học viên thành công", {
        type: "success",
      });
      reset();
    } catch (error) {
      showToast("Có lỗi xảy ra! Chỉnh sửa không thành công", {
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (language) {
      reset(language);
    }
  }, [language, reset]);

  const handleCloseModal = () => {
    handleClose();
  };

  const PAYMENT_STATUS = [
    {
      id: "REQUESTED",
      name: "Gửi Yêu Cầu",
    },
    {
      id: "APPROVED",
      name: "Chấp Nhận",
    },
    {
      id: "REJECTED",
      name: "Từ Chối",
    },
  ];

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>
          Kiểm tra thông tin chuyển khoản và cập nhật trạng thái cho học viên
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontWeight: "600", padding: "5px 0" }}>
              Hình ảnh chuyển khoản
            </div>
            <img
              src={getImage(language?.image)}
              alt={language?.name}
              style={{ width: "300px", height: "auto", objectFit: "cover" }}
            />
          </div>

          <Form.Group
            controlId="paymentStatus"
            style={{ fontWeight: "600", padding: "5px 0" }}
          >
            <Form.Label>
              Trạng Thái Thanh Toán <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              as="select"
              {...register("paymentStatus", {
                required: "Trạng thái là bắt buộc",
              })}
              isInvalid={!!errors.payment}
            >
              <option value="">Select</option>
              {PAYMENT_STATUS.map((option) => (
                <option value={option.id}>{option.name}</option>
              ))}
            </Form.Control>
            {errors.password && (
              <Form.Control.Feedback type="invalid">
                {errors.paymentStatus.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Đóng
            </Button>
            <Button variant="primary" type="submit">
              Cập nhật
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditLanguageModal;
