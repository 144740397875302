import { Row, Col } from "react-bootstrap";
import SquareBox from "./SquareBoxMenu";
import image1 from "../assets/images/Slide1.JPG";
import { useWindowWidth } from "../context/WindowWidthContext";

const SessionMenu = () => {
  const { isMobile } = useWindowWidth();
  return (
    <Row
      style={{
        padding: !isMobile ? "20px 30px 10px 30px" : "10px 0px 10px 0px",
      }}
    >
      <Col md={3} xs={4}>
        <SquareBox
          imageUrl={image1}
          name={"JLPT"}
          description={"Full Video, Tài Liệu"}
          url={"/jlpt-category"}
        />
      </Col>
      <Col md={3} xs={4}>
        <SquareBox
          imageUrl={image1}
          name={"Kaiwa"}
          description={"1000+ Mẫu Kaiwa Hay"}
          url={"/kaiwa-category"}
        />
      </Col>
      <Col md={3} xs={4}>
        <SquareBox
          imageUrl={image1}
          name={"Luyện Thi"}
          description={"Full các trình độ"}
          url={"/listening-category"}
        />
      </Col>
      {!isMobile && (
        <Col md={3} xs={4}>
          <SquareBox
            imageUrl={image1}
            name={"update"}
            description={"Các khóa học update"}
            url={"/update-category"}
          />
        </Col>
      )}
    </Row>
  );
};

export default SessionMenu;
