import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import httpService from "../../../../utils/request";
import { useToast } from "../../../../context/ToastContext";
import { DOMAIN } from "../../../../constants";
import { RotatingLines } from "react-loader-spinner";

const languageSchema = z.object({
  title: z.string().optional().nullable(),
});

const EditLanguageModal = ({ show, handleClose, language, handleUpdate }) => {
  const showToast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: language,
    resolver: zodResolver(languageSchema),
  });

  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const imageFile = watch("image");

  useEffect(() => {
    if (imageFile && imageFile.length > 0) {
      const file = imageFile[0];
      if (file && file instanceof File) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setImagePreview(null);
    }
  }, [imageFile]);

  useEffect(() => {
    if (language) {
      reset(language);
      language?.image && setImagePreview(`${DOMAIN}${language?.image}`);
    }
  }, [language, reset]);

  const onSubmit = async (data) => {
    try {
      try {
        setLoading(true);
        let imageData = imagePreview;
        if (imagePreview && imagePreview.includes("base64")) {
          imageData = await httpService.post("/private/upload", {
            image: imagePreview,
          });
        }
        const dataResquest = {
          ...data,
          image: imageData.replace(DOMAIN, ""),
          type: "BANNER",
        };
        const response = await httpService.put(
          `/private/banners/${language.id}`,
          dataResquest
        );
        handleUpdate({ id: response.id, ...dataResquest });
        setLoading(false);
        showToast("Chỉnh sửa thành công", { type: "success" });
        reset();
        setImagePreview(null);
      } catch (error) {
        showToast(error.response.data, {
          position: "top-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      showToast("Có lỗi xảy ra", { type: "error" });
    }
  };

  const handleCloseModal = () => {
    reset();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>Chỉnh sửa ảnh banner</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="languageName">
            <Form.Label>
              Mô tả <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="text"
              {...register("title")}
              isInvalid={!!errors.title}
            />
            {errors.title && (
              <Form.Control.Feedback type="invalid">
                {errors.title.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group controlId="courseCategoryImage">
            <Form.Label>
              Ảnh <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              isInvalid={!!errors.image}
              type="file"
              accept="image/*"
              {...register("image", { required: "File is required" })}
            />
            {imagePreview && (
              <div className="mt-3">
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{
                    width: "160px",
                    height: "auto",
                    objectFit: "cover",
                  }}
                />
              </div>
            )}
            {errors.image && (
              <Form.Control.Feedback type="invalid">
                {errors.image.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Đóng
            </Button>
            <Button variant="primary" type="submit">
              Cập nhật
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
      {loading && (
        <div style={{ position: "absolute", top: "30%", left: "40%" }}>
          <RotatingLines color="green" radius={"8px"} />
        </div>
      )}
    </Modal>
  );
};

export default EditLanguageModal;
