import React, { useState, useEffect } from "react";
import TitlePrimary from "./TitlePrimary";
import SquareBoxCategory from "./SquareBoxCategory";
import { Row, Col } from "react-bootstrap";

const MasterCategory = ({ masterCategory, hideHeader }) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const categories = masterCategory.masterCategories || [];
  return (
    <div>
      {!hideHeader && (
        <TitlePrimary
          name={masterCategory.name}
          description={masterCategory.description}
          color={masterCategory.color}
        />
      )}

      <Row style={{ padding: width < 768 ? "0 10px" : "0 50px" }}>
        {categories.map((category, index) => (
          <Col key={index} md={4} xs={4} sm={4}>
            <SquareBoxCategory
              imageUrl={category.image}
              text={category.name}
              description={category.description}
              id={category.id}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default MasterCategory;
