import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "../components/styles/login.css";
import httpService from "../utils/request";
import { useNavigate } from "react-router-dom";
import { useToast } from "../context/ToastContext";

function Login() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const showToast = useToast();

  const validatePhone = (phone) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(phone);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!email) newErrors.email = "Tài khoản là bắt buộc!";
    if (!name) newErrors.name = "Họ tên là bắt buộc!";
    if (!phone) newErrors.phone = "Số điện thoại là bắt buộc!";
    else if (!validatePhone(phone)) {
      newErrors.phone = "Số điện thoại không đúng định dạng!";
    }

    if (!password) newErrors.password = "Mật khẩu là bắt buộc!";
    else if (password.length < 6)
      newErrors.password = "Mật khẩu phải có ít nhất 6 ký tự!";

    if (!confirmPassword) newErrors.confirmPassword = "Mật khẩu là bắt buộc!";
    else if (confirmPassword.length < 6)
      newErrors.confirmPassword = "Mật khẩu phải có ít nhất 6 ký tự!";

    if (password && confirmPassword && password !== confirmPassword) {
      newErrors.confirmPassword = "Mật khẩu không khớp!";
      newErrors.password = "Mật khẩu không khớp!";
    }

    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setErrors({});
      try {
        await httpService.post(`/auth/signup`, {
          username: email,
          password,
          name,
          phone,
        });
        showToast("Đã đăng ký thành công, vui lòng đăng nhập tại đây.", {
          position: "top-right",
          autoClose: 3000,
          type: "success",
        });
        navigate("/login");
      } catch (error) {
        showToast(error.response.data, {
          position: "top-right",
          autoClose: 3000,
          type: "error",
        });
      }
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login-form-container">
        <h2 className="login-title" style={{ textAlign: "center" }}>
          Tạo Tài Khoản
        </h2>
        <div className="login-title-sub">
          <p className="no-margin">
            Gia nhập hệ sinh thái của chúng tôi để cùng cập nhật những bài học
            mới ngay nhé!
          </p>
        </div>
        <Form onSubmit={handleSubmit} className="login-form">
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Họ Tên</Form.Label>
            <Form.Control
              style={{ border: "1px solid #57d061" }}
              placeholder="Họ Tên"
              value={name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="phone">
            <Form.Label>Số Điện Thoại</Form.Label>
            <Form.Control
              style={{ border: "1px solid #57d061" }}
              placeholder="Số Điện Thoại"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              isInvalid={!!errors.phone}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Tài Khoản</Form.Label>
            <Form.Control
              style={{ border: "1px solid #57d061" }}
              placeholder="Tài Khoản"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Mật Khẩu</Form.Label>
            <Form.Control
              style={{ border: "1px solid #57d061" }}
              type="password"
              placeholder="Mật Khẩu"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Nhập Lại Mật Khẩu</Form.Label>
            <Form.Control
              style={{ border: "1px solid #57d061" }}
              type="password"
              placeholder="Mật Khẩu"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              isInvalid={!!errors.confirmPassword}
            />
            <Form.Control.Feedback type="invalid">
              {errors.confirmPassword}
            </Form.Control.Feedback>
          </Form.Group>

          <div style={{ display: "flex", gap: "5px" }}>
            <Button variant="success" type="submit" className="login-button">
              Đăng Ký Ngay
            </Button>
            <Button
              onClick={() => {
                window.location.href = "https://www.khotiengnhat1.com/xacnhan";
              }}
              variant="success"
              className="login-button"
            >
              Thanh Toán Ngay
            </Button>
          </div>

          <div style={{ marginTop: "10px", textAlign: "center" }}>
            Bạn đã có tài khoản?{" "}
            <span
              onClick={() => {
                navigate("/login");
              }}
              style={{ color: "blue", cursor: "pointer" }}
            >
              Đăng nhập ngay
            </span>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Login;
