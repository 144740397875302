import React from "react";
import { Routes, Route } from "react-router-dom";
import RootCategory from "./Home";
import ManagTeacher from "./ManageTeacher";
import AdminLayout from "./AdminLayout";
import { isAdmin } from "../../utils";
import ManageLanguage from "./ManageLanguage";
import Setting from "./Setting";
import ManageUser from "./ManageUser";
import ManagePayment from "./ManagePayment";

const Admin = () => {
  return (
    <AdminLayout>
      {isAdmin() ? (
        <Routes>
          <Route path="/" exact element={<RootCategory />} />
          <Route path="/root-category" element={<RootCategory />} />
          <Route path="/manage-teacher" element={<ManagTeacher />} />
          <Route path="/manage-language" element={<ManageLanguage />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/users" element={<ManageUser />} />
          <Route path="/payments" element={<ManagePayment />} />
        </Routes>
      ) : (
        <>Không có quyền truy cập</>
      )}
    </AdminLayout>
  );
};

export default Admin;
