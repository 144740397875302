import React, { useMemo } from "react";
import DataTable1 from "../../components/admin/DataTable";
import { EditButton, DeleteButton } from "../../components/admin/button";
import { useEffect, useState } from "react";
import { Button, Accordion, Form } from "react-bootstrap";
import CreateLanguageModal from "../../components/admin/modals/banner/CreateLanguage";
import EditLanguageModal from "../../components/admin/modals/banner/EditLanguage";
import DeleteConfirmModal from "../../components/admin/modals/banner/DeleteLanguage";
import CreateLanguageModalA from "../../components/admin/modals/popup/CreateLanguage";
import EditLanguageModalA from "../../components/admin/modals/popup/EditLanguage";
import DeleteConfirmModalA from "../../components/admin/modals/popup/DeleteLanguage";

import CreateLanguageModalB from "../../components/admin/modals/bank/CreateLanguage";
import EditLanguageModalB from "../../components/admin/modals/bank/EditLanguage";
import DeleteConfirmModalB from "../../components/admin/modals/bank/DeleteLanguage";

import { fetchList, updatePut } from "../../services";
import { useToast } from "../../context/ToastContext";
import { DOMAIN } from "../../constants";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import httpService from "../../utils/request";

const supportSchema = z.object({
  zaloUrl: z.string().optional().nullable(),
  fbUrl: z.string().optional().nullable(),
  paymentUrl: z.string().optional().nullable(),
  phone: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
});

const ManageLanguage = () => {
  const showToast = useToast();

  const [listLanguages, setListLanguages] = useState([]);
  const [listBanks, setListBanks] = useState([]);
  const [listImagePopups, setListImagePopups] = useState([]);
  const [support, setSupport] = useState({});

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const [showCreateModalA, setShowCreateModalA] = useState(false);
  const [showEditModalA, setShowEditModalA] = useState(false);
  const [showDeleteModalA, setShowDeleteModalA] = useState(false);
  const [selectedItemA, setSelectedItemA] = useState(null);

  const [showCreateModalB, setShowCreateModalB] = useState(false);
  const [showEditModalB, setShowEditModalB] = useState(false);
  const [showDeleteModalB, setShowDeleteModalB] = useState(false);
  const [selectedItemB, setSelectedItemB] = useState(null);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        isFilter: false,
      },
      {
        accessorKey: "title",
        header: "Mô tả",
      },
      {
        accessorKey: "image",
        header: "Ảnh",
        cell: ({ row }) => (
          <img
            src={`${DOMAIN}${row.original.image}`}
            alt={row.original.name}
            style={{ width: "150px", height: "auto", objectFit: "cover" }}
          />
        ),
      },
      {
        header: "Hành động",
        isFilter: false,
        cell: ({ row }) => (
          <div style={{ display: "flex", gap: "10px" }}>
            <EditButton onClick={() => handleShowEditModal(row.original)} />
            <DeleteButton onClick={() => handleShowDeleteModal(row.original)} />
          </div>
        ),
      },
    ],
    [listLanguages]
  );

  const columnImagePopups = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        isFilter: false,
      },
      {
        accessorKey: "title",
        header: "Mô tả",
      },
      {
        accessorKey: "image",
        header: "Ảnh",
        cell: ({ row }) => (
          <img
            src={`${DOMAIN}${row.original.image}`}
            alt={row.original.name}
            style={{ width: "150px", height: "auto", objectFit: "cover" }}
          />
        ),
      },
      {
        header: "Hành động",
        isFilter: false,
        cell: ({ row }) => (
          <div style={{ display: "flex", gap: "10px" }}>
            <EditButton onClick={() => handleShowEditModalA(row.original)} />
            <DeleteButton
              onClick={() => handleShowDeleteModalA(row.original)}
            />
          </div>
        ),
      },
    ],
    [listLanguages]
  );

  const columnBanks = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        isFilter: false,
      },
      {
        accessorKey: "bankName",
        header: "Tên Ngân Hàng",
      },
      {
        accessorKey: "accountName",
        header: "Tên Chủ Tài Khoản",
      },
      {
        accessorKey: "bankNumber",
        header: "Số Tài Khoản",
      },
      {
        accessorKey: "image",
        header: "Ảnh",
        cell: ({ row }) => (
          <img
            src={`${DOMAIN}${row.original.image}`}
            alt={row.original.name}
            style={{ width: "150px", height: "auto", objectFit: "cover" }}
          />
        ),
      },
      {
        header: "Hành động",
        isFilter: false,
        cell: ({ row }) => (
          <div style={{ display: "flex", gap: "10px" }}>
            <EditButton onClick={() => handleShowEditModalB(row.original)} />
            <DeleteButton
              onClick={() => handleShowDeleteModalB(row.original)}
            />
          </div>
        ),
      },
    ],
    [listLanguages]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: zodResolver(supportSchema),
  });

  const handleShowCreateModal = () => setShowCreateModal(true);

  const handleShowEditModal = (item) => {
    setShowEditModal(true);
    setSelectedItem(item);
  };
  const handleShowDeleteModal = (item) => {
    setShowDeleteModal(true);
    setSelectedItem(item);
  };

  const handleCreate = (newLanguage) => {
    setListLanguages((prevLanguages) => [...prevLanguages, newLanguage]);
    setShowCreateModal(false);
  };

  const handleUpdate = (updatedLanguage) => {
    setListLanguages((prevLanguages) =>
      prevLanguages.map((language) =>
        language.id === updatedLanguage.id ? updatedLanguage : language
      )
    );
    setShowEditModal(false);
  };

  const handleDelete = async () => {
    const response = await updatePut(`/private/banners/${selectedItem.id}`, {
      ...selectedItem,
      status: false,
    });
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setShowDeleteModal(false);
      setListLanguages((prevDatas) =>
        prevDatas.filter((pre) => pre.id !== selectedItem.id)
      );
      showToast("Xóa thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const handleShowCreateModalA = () => setShowCreateModalA(true);

  const handleShowEditModalA = (item) => {
    setShowEditModalA(true);
    setSelectedItemA(item);
  };
  const handleShowDeleteModalA = (item) => {
    setShowDeleteModalA(true);
    setSelectedItemA(item);
  };

  const handleCreateA = (newLanguage) => {
    setListImagePopups((prevLanguages) => [...prevLanguages, newLanguage]);
    setShowCreateModalA(false);
  };

  const handleUpdateA = (updatedLanguage) => {
    setListImagePopups((prevLanguages) =>
      prevLanguages.map((language) =>
        language.id === updatedLanguage.id ? updatedLanguage : language
      )
    );
    setShowEditModalA(false);
  };

  const handleDeleteA = async () => {
    const response = await updatePut(`/private/banners/${selectedItemA.id}`, {
      ...selectedItemA,
      status: false,
    });
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setShowDeleteModalA(false);
      setListImagePopups((prevDatas) =>
        prevDatas.filter((pre) => pre.id !== selectedItemA.id)
      );
      showToast("Xóa thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  const handleShowCreateModalB = () => setShowCreateModalB(true);

  const handleShowEditModalB = (item) => {
    setShowEditModalB(true);
    setSelectedItemB(item);
  };
  const handleShowDeleteModalB = (item) => {
    setShowDeleteModalB(true);
    setSelectedItemB(item);
  };

  const handleCreateB = (newLanguage) => {
    setListBanks((prevLanguages) => [...prevLanguages, newLanguage]);
    setShowCreateModalB(false);
  };

  const handleUpdateB = (updatedLanguage) => {
    setListBanks((prevLanguages) =>
      prevLanguages.map((language) =>
        language.id === updatedLanguage.id ? updatedLanguage : language
      )
    );
    setShowEditModalB(false);
  };

  const handleDeleteB = async () => {
    const response = await updatePut(`/private/banks/${selectedItemB.id}`, {
      ...selectedItemB,
      status: false,
    });
    if (response.error) {
      showToast(response.message, {
        position: "top-right",
        autoClose: 3000,
        type: "error",
      });
    } else {
      setShowDeleteModalB(false);
      setListBanks((prevDatas) =>
        prevDatas.filter((pre) => pre.id !== selectedItemB.id)
      );
      showToast("Xóa thành công", {
        position: "top-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };

  useEffect(() => {
    const fetchListLanguages = async () => {
      const data = await fetchList("/public/banners");
      const banks = await fetchList("/public/banks");
      setListBanks(banks);
      setListLanguages(data.filter((x) => x.type === "BANNER"));
      setListImagePopups(
        data.filter(
          (x) => x.type === "POPUP_REGISTER" || x.type === "POPUP_PAYMENT"
        )
      );
      const support = data.find((x) => x.type === "SUPPORT") || {};
      setSupport(support);
    };
    fetchListLanguages();
  }, []);

  useEffect(() => {
    if (support) {
      reset(support);
    }
  }, [support, reset]);

  const onSubmit = async (data) => {
    try {
      try {
        const dataResquest = {
          ...data,
          type: "SUPPORT",
        };
        if (support.id) {
          await httpService.put(`/private/banners/${support.id}`, dataResquest);
        } else {
          await httpService.post(`/private/banners`, dataResquest);
        }
        showToast("Chỉnh sửa thành công", { type: "success" });
      } catch (error) {
        showToast(error.response.data, {
          position: "top-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      showToast("Có lỗi xảy ra", { type: "error" });
    }
  };

  return (
    <div style={{ padding: "10px 0" }}>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Quản lý Ảnh Banner</Accordion.Header>
          <Accordion.Body>
            <h4>Quản lý Ảnh Banner</h4>
            <Button
              variant="primary"
              className="mb-2"
              onClick={handleShowCreateModal}
            >
              Thêm mới
            </Button>
            <DataTable1 data={listLanguages} columns={columns} />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>Quản lý Ảnh Popup</Accordion.Header>
          <Accordion.Body>
            <h4>Quản lý Ảnh Popup</h4>
            <Button
              variant="primary"
              className="mb-2"
              onClick={handleShowCreateModalA}
            >
              Thêm mới
            </Button>
            <DataTable1 data={listImagePopups} columns={columnImagePopups} />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Quản Lý Ngân Hàng Thanh Toán</Accordion.Header>
          <Accordion.Body>
            <h4>Quản Lý Ngân Hàng Thanh Toán</h4>
            <Button
              variant="primary"
              className="mb-2"
              onClick={handleShowCreateModalB}
            >
              Thêm mới
            </Button>
            <DataTable1 data={listBanks} columns={columnBanks} />
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>Quản Lý Link Hỗ Trợ</Accordion.Header>
          <Accordion.Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group controlId="zaloUrl">
                <Form.Label>
                  Zalo <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("zaloUrl")}
                  isInvalid={!!errors.zaloUrl}
                />
                {errors.zaloUrl && (
                  <Form.Control.Feedback type="invalid">
                    {errors.zaloUrl.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group controlId="fbUrl">
                <Form.Label>
                  Facebook <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("fbUrl")}
                  isInvalid={!!errors.fbUrl}
                />
                {errors.fbUrl && (
                  <Form.Control.Feedback type="invalid">
                    {errors.fbUrl.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group controlId="paymentUrl">
                <Form.Label>
                  Link hướng dẫn thanh toán{" "}
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("paymentUrl")}
                  isInvalid={!!errors.paymentUrl}
                />
                {errors.paymentUrl && (
                  <Form.Control.Feedback type="invalid">
                    {errors.paymentUrl.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group controlId="name">
                <Form.Label>
                  Tên <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("name")}
                  isInvalid={!!errors.name}
                />
                {errors.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group controlId="phone">
                <Form.Label>
                  Số điện thoại <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register("phone")}
                  isInvalid={!!errors.phone}
                />
                {errors.phone && (
                  <Form.Control.Feedback type="invalid">
                    {errors.phone.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                style={{ marginTop: "10px" }}
              >
                Cập nhật
              </Button>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <CreateLanguageModal
        show={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        handleCreate={handleCreate}
      />
      <EditLanguageModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        language={selectedItem}
        handleUpdate={handleUpdate}
      />
      <DeleteConfirmModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={handleDelete}
      />

      <CreateLanguageModalA
        show={showCreateModalA}
        handleClose={() => setShowCreateModalA(false)}
        handleCreate={handleCreateA}
      />
      <EditLanguageModalA
        show={showEditModalA}
        handleClose={() => setShowEditModalA(false)}
        language={selectedItemA}
        handleUpdate={handleUpdateA}
      />
      <DeleteConfirmModalA
        show={showDeleteModalA}
        handleClose={() => setShowDeleteModalA(false)}
        handleDelete={handleDeleteA}
      />

      <CreateLanguageModalB
        show={showCreateModalB}
        handleClose={() => setShowCreateModalB(false)}
        handleCreate={handleCreateB}
      />
      <EditLanguageModalB
        show={showEditModalB}
        handleClose={() => setShowEditModalB(false)}
        language={selectedItemB}
        handleUpdate={handleUpdateB}
      />
      <DeleteConfirmModalB
        show={showDeleteModalB}
        handleClose={() => setShowDeleteModalB(false)}
        handleDelete={handleDeleteB}
      />
    </div>
  );
};

export default ManageLanguage;
