import React, { useState } from "react";
import DataTable from "../../components/admin/DataTable";
import { Button } from "react-bootstrap";
import CreateCourseCategoryModal from "../../components/admin/modals/part-category/CreateCategory";
import EditCourseCategoryModal from "../../components/admin/modals/part-category/EditCategory";
import { FaEdit, FaTrash } from "react-icons/fa";
import DeleteConfirmModal from "../../components/admin/modals/part-category/DeleteCategory";

const CourseCategory = ({
  masterCategories,
  parents,
  handleUpdateA,
  handleCreateA,
  handleDeleteA,
  masterCategoryId,
  teachers,
  handleChangeOrder,
}) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [listOrder, setListOrder] = useState([]);

  const handleShowCreateModal = () => setShowCreateModal(true);
  const handleShowEditModal = (item) => {
    setShowEditModal(true);
    setSelectedItem(item);
  };
  const handleShowDeleteModal = (item) => {
    setShowDeleteModal(true);
    setSelectedItem(item);
  };

  const handleCreate = (rootCategory) => {
    handleCreateA(rootCategory);
  };

  const handleUpdate = (updatedRootCategory) => {
    handleUpdateA(updatedRootCategory);
  };

  const handleDelete = () => {
    handleDeleteA(selectedItem.id);
    setShowDeleteModal(false);
  };

  const handleChangeOrderData = (order, id) => {
    setListOrder((prev) => {
      const data = prev.filter((x) => x.id !== id);
      return [...data, { order, id }];
    });
  };

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const EditableCell = ({ value: initialValue, id, handleChangeOrderData }) => {
    const [value, setValue] = useState(initialValue);

    const debouncedOnChange = debounce(handleChangeOrderData, 1000);

    const handleChange = (e) => {
      setValue(e.target.value);
      debouncedOnChange(e.target.value, id);
    };

    return <input type="text" value={value} onChange={handleChange} />;
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "order",
        header: "STT",
        enableSorting: true,
        isFilter: false,
        cell: ({ row }) => {
          return (
            <EditableCell
              value={row.original.order}
              id={row.original.id}
              handleChangeOrderData={handleChangeOrderData}
            />
          );
        },
      },
      {
        accessorKey: "name",
        header: "Bài học",
        enableSorting: true,
        isFilter: true,
      },
      {
        accessorKey: "description",
        header: "Mô tả",
        enableSorting: true,
        isFilter: true,
      },
      {
        accessorKey: "url",
        header: "Link",
        isFilter: true,
      },
      {
        accessorKey: "partCategory",
        header: "Danh mục gốc",
      },
      {
        header: "Hành động",
        cell: ({ row }) => (
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              variant="warning"
              size="sm"
              onClick={() => handleShowEditModal(row.original)}
            >
              <FaEdit />
            </Button>
            <Button
              variant="danger"
              size="sm"
              onClick={() => handleShowDeleteModal(row.original)}
            >
              <FaTrash />
            </Button>
          </div>
        ),
      },
    ],
    [masterCategories]
  );

  return (
    <div>
      <Button
        variant="primary"
        style={{ marginBottom: "8px" }}
        onClick={handleShowCreateModal}
      >
        Thêm mới
      </Button>
      <Button
        variant="primary"
        style={{ marginBottom: "8px", marginLeft: "8px" }}
        onClick={() => handleChangeOrder(listOrder)}
        disabled={listOrder.length === 0}
      >
        Cập nhật thứ tự
      </Button>
      <DataTable data={masterCategories} columns={columns} />
      <CreateCourseCategoryModal
        show={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
        handleCreate={handleCreate}
        parents={parents}
        categoryId={masterCategoryId}
        teachers={teachers}
      />
      <EditCourseCategoryModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        rootCategory={selectedItem}
        handleUpdate={handleUpdate}
        parents={parents}
        categoryId={masterCategoryId}
        teachers={teachers}
      />
      <DeleteConfirmModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default CourseCategory;
