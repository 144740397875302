import axios from "axios";
import { DOMAIN } from "../constants";

const API_URL = `${DOMAIN}/api`;

// Create an instance of axios
const api = axios.create({
  baseURL: API_URL, // Replace with your API base URL
  timeout: 20000, // Set a timeout (optional)
  headers: {
    "Content-Type": "application/json",
    // Add any other headers you need
  },
});

// Interceptors can be added here if needed
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token && config.url.includes("private")) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    // You can add authorization tokens or modify the config here
    return config;
  },
  (error) => {
    // Handle the request error here
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    // You can modify the response data here if needed
    return response.data;
  },
  (error) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    // Handle response errors here
    return Promise.reject(error);
  }
);

export default api;
