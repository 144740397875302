import React, { useState, useEffect } from "react";
import { GoClockFill } from "react-icons/go";
import { Row, Col } from "react-bootstrap";

const getYouTubeVideoId = (url) => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const YouTubeVideo = ({ url }) => {
  const videoId = getYouTubeVideoId(url);
  return (
    <div
      style={{
        position: "relative",
        paddingBottom: "56.25%",
        height: 0,
        overflow: "hidden",
        borderRadius: "8px",
      }}
    >
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allowFullScreen
        title="YouTube Video"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
};

const Item = ({ lesson }) => {
  return (
    <div
      className="d-flex"
      style={{
        margin: "10px 0",
      }}
    >
      <div
        style={{
          borderRadius: "8px",
          width: "200px",
          boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.3)",
        }}
      >
        <YouTubeVideo url={lesson.url} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "16px",
          justifyContent: "space-between",
          flex: 1,
          maxWidth: "250px",
        }}
      >
        <div style={{ fontSize: "14px", fontWeight: "bold" }}>
          {lesson.name}
          {lesson.documentUrl && lesson.documentUrl !== "" && (
            <div
              style={{
                fontWeight: "600",
              }}
            >
              <a target="_blank" href={lesson.documentUrl}>
                Link Tài Liệu
              </a>
            </div>
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              backgroundColor: "green",
              color: "white",
              padding: "5px",
              borderRadius: "5px",
            }}
          >
            Free
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              minWidth: "65px",
            }}
          >
            {lesson.time && (
              <>
                <GoClockFill />
                <p
                  style={{
                    fontSize: "12px",
                    marginLeft: "5px",
                    marginBottom: 0,
                  }}
                >
                  {lesson.time}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MasterCategoryUpdate = ({ masterCategories }) => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div>
      <Row style={{ padding: width < 768 ? "0 10px" : "0 50px" }}>
        {masterCategories.map((category, index) => (
          <Col key={index} md={6} xs={12}>
            <div
              style={{
                color: "purple",
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              {category.name}
            </div>
            {(category.courses || []).map((lesson) => (
              <Item key={lesson.id} lesson={lesson} />
            ))}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default MasterCategoryUpdate;
